import { ArrowRightIcon } from '@heroicons/react/solid';

interface Props {
  text: string;
  colour: string;
  onSubmit: () => void;
}
export const ModalSubmitButton = ({ text, colour, onSubmit }: Props) => {
  return (
    <button
      type="button"
      onClick={() => {
        onSubmit();
      }}
      className={`mt-6 -mb-4 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-${colour}-600 hover:bg-${colour}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${colour}-500`}
    >
      <span className="absolute right-0 inset-y-0 flex items-center pr-3">
        <ArrowRightIcon
          className={`h-5 w-5 text-${colour} -500 group-hover:text-${colour}-400`}
          aria-hidden="true"
        />
      </span>
      {text}
    </button>
  );
};
