import { useEffect, useState } from 'react';
import { LoadingIcon } from 'components/LoadingIcon';

interface Props {
  children: any;
}
export const LoadingLayout = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 250);
  });

  return (
    <>
      {loading ? (
        <div className="h-32 flex justify-center items-center">
          <LoadingIcon colour="gray" height="10" width="10" />
        </div>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};
