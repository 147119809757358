import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilIcon,
  PlusIcon,
} from '@heroicons/react/solid';
import { useView } from 'hooks/useView';
import { Role, User as UserModel } from 'models/Auth';
import { UserView } from 'views/UserView';
import { Badge } from 'components/Badge';
import {
  Category as CategoryModel,
  Identifier as IdentifierModel,
} from 'models/Product';
import { useState } from 'react';
import { Modal } from 'components/Modal';
import { Size } from 'models/UI';
import { Button } from 'components/Button';
import { Identifier } from './Identifier';
import { CreateIdentifier } from './CreateIdentifier';

interface Props {
  isChild?: boolean;
  category: CategoryModel;
  childPadding?: number;
}
export const Category = ({ isChild, category, childPadding }: Props) => {
  const view = useView(UserView);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [addIdentifier, setAddIdentifier] = useState(false);
  const [showChildren, setShowChildren] = useState(false);

  return (
    <>
      <tr key={category.id} className="hover:bg-gray-50 border-b">
        <td
          onClick={() => console.log('do nothing')}
          className="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
          style={{
            paddingLeft: (childPadding ? childPadding * 40 : 20) + 'px',
          }}
        >
          {category.name}
          <br />
          {category.children.length > 0 && (
            <div
              className="flex"
              onClick={() => {
                setShowChildren(!showChildren);
              }}
            >
              <div className="w-4 my-auto">
                {showChildren ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </div>
              <small>{showChildren ? 'Hide' : 'Show'} Children</small>
            </div>
          )}
        </td>
        <td
          className="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex my-auto"
          onClick={() => {
            setCategoryOpen(true);
          }}
        >
          {/* TODO: Just put the "Roles" in the edit user modal when the user exists. When you click a role, add that role, no need to save */}
          <PencilIcon className="w-5 h-5 text-gray-500 mr-2" />
          {category.identifiers && (
            <div className="flex">
              {category.identifiers.length > 0 ? (
                <>
                  {category.identifiers?.map(
                    (identifier: IdentifierModel, index: number) => {
                      return (
                        <div className="mr-2">
                          <Badge
                            colour="blue"
                            value={`${identifier.name} (${identifier.value})`}
                          />
                        </div>
                      );
                    }
                  )}
                </>
              ) : (
                <div className="my-auto">No identifiers</div>
              )}
            </div>
          )}
        </td>
      </tr>
      {category.children && showChildren && (
        <>
          {category.children.map((category: CategoryModel, index: number) => (
            <Category
              category={category}
              isChild={true}
              childPadding={childPadding ? childPadding + 1 : 1}
            />
          ))}
        </>
      )}
      <Modal
        title={`Edit Identifiers for ${category.name}`}
        isOpen={categoryOpen}
        setOpen={setCategoryOpen}
      >
        <table className="min-w-full divide-y divide-gray-200 mt-4">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Value
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              ></th>
            </tr>
          </thead>
          <tbody>
            {category.identifiers?.map(
              (identifier: IdentifierModel, index: number) => {
                return (
                  <Identifier
                    categoryId={category.id}
                    identifier={identifier}
                  />
                );
              }
            )}
          </tbody>
        </table>
        {addIdentifier ? (
          <CreateIdentifier categoryId={category.id} />
        ) : (
          <div className="w-full">
            <button
              type="button"
              onClick={() => setAddIdentifier(true)}
              className={`w-full mt-6 -mb-4 group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
            >
              <span className="absolute right-0 inset-y-0 flex items-center pr-3">
                <PlusIcon
                  className={`h-5 w-5 text-green-500 group-hover:text-green-400`}
                  aria-hidden="true"
                />
              </span>
              Add Identifier
            </button>
          </div>
        )}
      </Modal>
    </>
  );
};
