import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, fork, takeEvery } from '@redux-saga/core/effects';
import {
  CarrierReportRow,
  GroupedUpdateOrder,
  Order,
  PDIRReportRow,
  ReportError,
  ShippingUpdateType,
  UpdateOrder,
} from 'models/Reports';
import { PaginatedResponse } from 'models/Dashboard';
import { ShippingTask, TaskType } from 'models/Shipping';
import { Backend } from 'services/Backend';
import { container } from 'tsyringe';
import { OfficeService } from 'services/OfficeService';
import toast from 'react-hot-toast';

export interface ShippingState {
  random: boolean;
  pdirReport: Array<PDIRReportRow>;
  carrierReport: Array<CarrierReportRow>;
  updateReport?: Array<GroupedUpdateOrder>;
  updateReportParseError: boolean;
  updateReportType?: ShippingUpdateType;
  reportError?: ReportError;
  trackingReport?: Array<Order>;
  shippingTrackingTasks?: PaginatedResponse<ShippingTask>;
  shippingUpdateTasks?: PaginatedResponse<ShippingTask>;
  task?: ShippingTask;
}

const initialState = {
  random: false,
  pdirReport: [],
  updateReportParseError: false,
  carrierReport: [],
  reportError: undefined,
  trackingReport: undefined,
} as ShippingState;

export const shipping = createSlice({
  name: 'shipping',
  initialState: initialState,
  reducers: {
    ExampleReducer(state: ShippingState, action: PayloadAction<{}>) {},

    SetPDIRReport(
      state: ShippingState,
      action: PayloadAction<{ pdirReport: Array<PDIRReportRow> }>
    ) {
      state.pdirReport = action.payload.pdirReport;
    },

    SetCarrierReport(
      state: ShippingState,
      action: PayloadAction<{ carrierReport: Array<CarrierReportRow> }>
    ) {
      state.carrierReport = action.payload.carrierReport;
    },

    SetReportError(
      state: ShippingState,
      action: PayloadAction<{ reportError: ReportError }>
    ) {
      state.reportError = action.payload.reportError;
    },

    SetTrackingReport(
      state: ShippingState,
      action: PayloadAction<{ trackingReport: Array<Order> }>
    ) {
      state.trackingReport = action.payload.trackingReport;
    },

    ClearTrackingReport(state: ShippingState) {
      state.trackingReport = undefined;
    },

    GetShippingTrackingTasks(
      state: ShippingState,
      action: PayloadAction<{ page?: number }>
    ) {},

    SetShippingTrackingTasks(
      state: ShippingState,
      action: PayloadAction<{
        shippingTrackingTasks: PaginatedResponse<ShippingTask>;
      }>
    ) {
      state.shippingTrackingTasks = action.payload.shippingTrackingTasks;
    },

    GetShippingUpdateTasks(
      state: ShippingState,
      action: PayloadAction<{ page?: number }>
    ) {},

    SetShippingUpdateTasks(
      state: ShippingState,
      action: PayloadAction<{
        shippingUpdateTasks: PaginatedResponse<ShippingTask>;
      }>
    ) {
      state.shippingUpdateTasks = action.payload.shippingUpdateTasks;
    },

    ClearUpdateReport(state: ShippingState) {
      state.updateReport = undefined;
    },

    SetUpdateReport(
      state: ShippingState,
      action: PayloadAction<{ updateReport: Array<GroupedUpdateOrder> }>
    ) {
      state.updateReport = action.payload.updateReport;
    },

    SetUpdateReportType(
      state: ShippingState,
      action: PayloadAction<{ updateReportType: ShippingUpdateType }>
    ) {
      state.updateReportType = action.payload.updateReportType;
    },

    SetUpdateReportParseError(
      state: ShippingState,
      action: PayloadAction<{ updateReportParseError: boolean }>
    ) {
      state.updateReportParseError = action.payload.updateReportParseError;
    },

    SubmitShippingUpdateTask(
      state: ShippingState,
      action: PayloadAction<{ shippingUpdate: Array<UpdateOrder> }>
    ) {},

    SubmitShippingTrackingTask(
      state: ShippingState,
      action: PayloadAction<{ shippingTracking: Array<Order> }>
    ) {},

    GetShippingTask(
      state: ShippingState,
      action: PayloadAction<{ id: string; type: TaskType }>
    ) {},

    SetShippingTask(
      state: ShippingState,
      action: PayloadAction<{ task: ShippingTask }>
    ) {
      state.task = action.payload.task;
    },

    ClearShippingTask(state: ShippingState) {
      state.task = undefined;
    },
  },
});

export const shippingActions = {
  ...shipping.actions,
};

export const shippingSelector = (state: any) =>
  state[shipping.name] as ShippingState;

function* shippingSideEffects() {
  const backend = container.resolve(Backend);
  const officeService = container.resolve(OfficeService);

  yield takeEvery(shippingActions.GetShippingUpdateTasks, function* (action) {
    try {
      const response: PaginatedResponse<ShippingTask> =
        yield backend.getShippingUpdateTasks(
          officeService.getOfficeAPIName(),
          action.payload.page
        );
      yield put(
        shippingActions.SetShippingUpdateTasks({
          shippingUpdateTasks: response,
        })
      );
    } catch {}
  });

  yield takeEvery(shippingActions.GetShippingTrackingTasks, function* (action) {
    try {
      const response: PaginatedResponse<ShippingTask> =
        yield backend.getShippingTrackingTasks(
          officeService.getOfficeAPIName(),
          action.payload.page
        );
      yield put(
        shippingActions.SetShippingTrackingTasks({
          shippingTrackingTasks: response,
        })
      );
    } catch {}
  });

  yield takeEvery(shippingActions.SubmitShippingUpdateTask, function* (action) {
    try {
      const response: ShippingTask = yield backend.submitShippingUpdateTask(
        officeService.getOfficeAPIName(),
        action.payload.shippingUpdate
      );
      yield put(shippingActions.ClearUpdateReport());
      yield put(shippingActions.GetShippingUpdateTasks({}));
      toast.success('Successfully submitted Shipping Update Report.');
    } catch (e: any) {
      console.log(e);
      toast.error('Error submitting Shipping Update Report.');
    }
  });

  yield takeEvery(
    shippingActions.SubmitShippingTrackingTask,
    function* (action) {
      try {
        const response: ShippingTask = yield backend.submitShippingTrackingTask(
          officeService.getOfficeAPIName(),
          action.payload.shippingTracking
        );
        yield put(shippingActions.ClearTrackingReport());
        yield put(shippingActions.GetShippingTrackingTasks({}));
        toast.success('Successfully submitted Shipping Tracking Report.');
      } catch (e: any) {
        console.log(e);
        toast.error('Error submitting Shipping Update Report.');
      }
    }
  );

  yield takeEvery(shippingActions.GetShippingTask, function* (action) {
    try {
      const response: ShippingTask = yield backend.getShippingTask(
        officeService.getOfficeAPIName(),
        action.payload.type === TaskType.SHIPPING ? 'shipping' : 'updates',
        action.payload.id
      );
      yield put(shippingActions.SetShippingTask({ task: response }));
    } catch (e: any) {}
  });
}

export function* shippingSaga() {
  yield fork(shippingSideEffects);
}
