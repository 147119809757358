import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  LocationMarkerIcon,
  HomeIcon,
  MailIcon,
  UsersIcon,
  ArchiveIcon,
  XIcon,
  OfficeBuildingIcon,
  SelectorIcon,
  CollectionIcon,
} from '@heroicons/react/outline';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from 'utils/styles';
import { useLocation } from 'react-router-dom';
import { container } from 'tsyringe';
import { OfficeService } from 'services/OfficeService';
import toast from 'react-hot-toast';
import { OfficeSelector } from './OfficeSelector';

const navigation = [
  { name: 'Home', href: '/', icon: HomeIcon, current: true },
  {
    name: 'Shipping Tracking',
    href: '/dashboard/shipping/tracking',
    icon: LocationMarkerIcon,
  },
  {
    name: 'Shipping Updates',
    href: '/dashboard/shipping/updates',
    icon: MailIcon,
  },
  { name: 'Users', href: '/dashboard/users', icon: UsersIcon },
  { name: 'Products', href: '/dashboard/products', icon: ArchiveIcon },
  {
    name: 'Categories',
    href: '/dashboard/products/categories',
    icon: CollectionIcon,
  },
];

interface Props {
  sidebarOpen: boolean;
  setSidebarOpen: (sidebarOpen: boolean) => void;
}

export const DashboardNav = ({ sidebarOpen, setSidebarOpen }: Props) => {
  const location = useLocation();
  const officeService = container.resolve(OfficeService);
  const [selectedOffice, setSelectedOffice] = useState(
    officeService.getOffice()
  );

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center -mt-3">
                <OfficeSelector />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2">
                  <div className="space-y-1">
                    {navigation.map((item) => (
                      <Link to={item.href}>
                        <span
                          key={item.name}
                          className={classNames(
                            item.href === location.pathname
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50',
                            'group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md'
                          )}
                          aria-current={
                            item.href === location.pathname ? 'page' : undefined
                          }
                        >
                          <item.icon
                            className={classNames(
                              item.href === location.pathname
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </span>
                      </Link>
                    ))}
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0">
        <div className="flex flex-col w-64 border-r border-gray-200 -mt-2 pb-4 bg-gray-100">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="mt-3 h-0 flex-1 flex flex-col overflow-y-auto">
            {/* User account dropdown */}
            <OfficeSelector />
            {/* Navigation */}
            <nav className="px-3 mt-6">
              <div className="space-y-1">
                {navigation.map((item) => (
                  <Link to={item.href}>
                    <span
                      key={item.name}
                      className={classNames(
                        item.href === location.pathname
                          ? 'bg-gray-200 text-gray-900'
                          : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
                        'group flex items-center px-2 py-2.5 text-sm font-medium rounded-md'
                      )}
                      aria-current={
                        item.href === location.pathname ? 'page' : undefined
                      }
                    >
                      <item.icon
                        className={classNames(
                          item.href === location.pathname
                            ? 'text-gray-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'mr-3 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </span>
                  </Link>
                ))}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
