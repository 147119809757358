import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useView } from 'hooks/useView';
import { Role, User } from 'models/Auth';
import { UserView } from 'views/UserView';
import { Badge } from 'components/Badge';
import { ModalSubmitButton } from 'components/ModalSubmitButton';
import { NonFormCheckbox } from 'components/NonFormCheckbox';
import { Paginator } from 'components/Paginator';

interface Props {
  user: User;
}

export const UserRoleSelector = ({ user }: Props) => {
  const view = useView(UserView);
  const [selectedRoles, setSelectedRoles] = useState(user.roles);

  useEffect(() => {
    setSelectedRoles(user.roles);
  }, [user.roles]);

  const onRoleAdd = (role: Role) => {
    if (selectedRoles) {
      const roleExistsSearch = selectedRoles.filter(
        (element: Role) => role.id === element.id
      );
      if (roleExistsSearch.length === 0) {
        setSelectedRoles([...selectedRoles, role]);
      }
    }
  };

  const onRoleRemove = (role: Role) => {
    if (selectedRoles) {
      const currentlySelectedRoles = selectedRoles.filter(
        (element: Role) => role.id !== element.id
      );
      setSelectedRoles(currentlySelectedRoles);
    }
  };

  const isRoleSelected = (role: Role): boolean => {
    if (selectedRoles) {
      return (
        selectedRoles.filter((element: Role) => role.id === element.id).length >
        0
      );
    }
    return false;
  };

  return (
    <>
      <div className="mb-4">
        {selectedRoles && (
          <>
            {selectedRoles.length > 0 && (
              <>
                <label
                  htmlFor="roles"
                  className="block text-sm font-medium text-gray-700 mt-4 mb-1"
                >
                  Selected Roles:
                </label>
                <div className="w-full flex flex-wrap">
                  {selectedRoles.map((role: Role) => {
                    return (
                      <div className="mr-2">
                        <Badge colour="blue" value={role.name} />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
      </div>
      {view.state.roles?.data.length > 0 ? (
        <>
          <div className="flex flex-col">
            <div className="-my-2 sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="border-b">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        ></th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {view.state.roles?.data.map(
                        (role: Role, index: number) => {
                          return (
                            <tr key={role.id} className="hover:bg-gray-50">
                              <td className="w-10 cursor-pointer pl-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <NonFormCheckbox
                                  name="roleChecked"
                                  isChecked={isRoleSelected(role)}
                                  onCheck={() => onRoleAdd(role)}
                                  onUnCheck={() => onRoleRemove(role)}
                                />
                              </td>
                              <td className="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {role.name}
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Paginator
            total={view.state.roles.total}
            page={view.state.roles.page}
            numberOnPage={view.state.roles.data.length}
            previousCallback={() => view.getRoles(view.state.roles.page - 1)}
            nextCallback={() => view.getRoles(view.state.roles.page + 1)}
          />
          <div className="w-full">
            <ModalSubmitButton
              onSubmit={() => {
                if (selectedRoles) {
                  if (selectedRoles.length > 0) {
                    view.modifyUserRoles(selectedRoles);
                  } else {
                    toast.error(
                      'You must choose at least one role in order to Modify the Users roles'
                    );
                  }
                }
              }}
              colour="blue"
              text={'Modify Roles'}
            />
          </div>
        </>
      ) : (
        <div className="my-10">No roles.</div>
      )}
    </>
  );
};
