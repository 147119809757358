import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { container } from 'tsyringe';
import { LoadingIcon } from 'components/LoadingIcon';
import { useView } from 'hooks/useView';
import { AuthService } from 'services/AuthService';
import { ProtectedLayoutView } from 'views/ProtectedLayoutView';
import { useLocation } from 'react-router';

interface Props {
  children: any;
  permission?: string;
}

export const ProtectedLayout = ({ children, permission }: Props) => {
  const view = useView(ProtectedLayoutView);
  const authService = container.resolve(AuthService);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (authService.isLoggedIn() || !view.state.loginRequired) {
      setIsAuthenticated(true);
    } else {
      authService.setNextURL(location.pathname);
      navigate('/login');
    }
  }, [authService.isLoggedIn(), view.state.loginRequired]);

  useEffect(() => {
    if (view.state.user.roles && permission) {
      if (!authService.userHasPermission(view.state.user, permission)) {
        navigate('/dashboard/no-permission');
      }
    }
  }, [view.state.user.roles]);

  return (
    <>
      {isAuthenticated ? (
        <>{children}</>
      ) : (
        <div className="h-screen w-full flex items-center justify-center">
          <LoadingIcon colour="black" height="12" width="15" />
        </div>
      )}
    </>
  );
};
