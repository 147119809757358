import { Menu, Transition } from '@headlessui/react';
import { OfficeBuildingIcon } from '@heroicons/react/outline';
import { SelectorIcon } from '@heroicons/react/solid';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { OfficeService } from 'services/OfficeService';
import { container } from 'tsyringe';
import { classNames } from 'utils/styles';

export const OfficeSelector = () => {
  const officeService = container.resolve(OfficeService);
  const [selectedOffice, setSelectedOffice] = useState(
    officeService.getOffice()
  );

  return (
    <Menu as="div" className="px-3 relative inline-block text-left mt-2 w-full">
      <div>
        <Menu.Button className="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500">
          <span className="flex w-full justify-between items-center">
            <span className="flex min-w-0 items-center justify-between space-x-3">
              <OfficeBuildingIcon className="h-9" />
              <span className="flex-1 flex flex-col min-w-0">
                <span className="text-gray-500 text-sm truncate">
                  Logged in to
                </span>
                <span className="text-gray-900 text-sm font-medium truncate">
                  {officeService.getOffice()}
                </span>
              </span>
            </span>
            <SelectorIcon
              className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
          <div className="py-1">
            {officeService.unSelectedOffices().map((office: string) => {
              return (
                <Menu.Item>
                  {({ active }) => (
                    <span
                      onClick={() => {
                        officeService.setOffice(office);
                        setSelectedOffice(officeService.getOffice());
                        toast.success(`Logged into ${office}`);
                      }}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm cursor-pointer'
                      )}
                    >
                      {office}
                    </span>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
