import { PencilIcon } from '@heroicons/react/solid';
import { useView } from 'hooks/useView';
import { Role, User as UserModel } from 'models/Auth';
import { UserView } from 'views/UserView';
import { Badge } from 'components/Badge';

interface Props {
  user: UserModel;
}
export const User = ({ user }: Props) => {
  const view = useView(UserView);

  const getUser = () => {
    if (user.id) {
      view.getUser(user.id);
      view.setUserActionModalOpen(true);
    }
  };

  return (
    <>
      <tr key={user.id} className="hover:bg-gray-50 border-b">
        <td
          onClick={() => getUser()}
          className="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
        >
          {user.username}
        </td>
        <td
          onClick={() => getUser()}
          className="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
        >
          {user.email}
        </td>
        <td
          className="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex my-auto"
          onClick={() => {
            if (user.id) {
              view.getUser(user.id);
              view.setUserRoleActionModalOpen(true);
            }
          }}
        >
          {/* TODO: Just put the "Roles" in the edit user modal when the user exists. When you click a role, add that role, no need to save */}
          <PencilIcon className="w-5 h-5 text-gray-500 mr-2" />
          {user.roles && (
            <div className="flex">
              {user.roles.length > 0 ? (
                <>
                  {user.roles?.map((role: Role, index: number) => {
                    return (
                      <div className="mr-2">
                        <Badge colour="blue" value={role.name} />
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="my-auto">No roles</div>
              )}
            </div>
          )}
        </td>
      </tr>
    </>
  );
};
