import { PhotographIcon, QrcodeIcon, SearchIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { container } from 'tsyringe';
import { DashboardHeading } from 'components/DashboardHeading';
import { useView } from 'hooks/useView';
import { DashboardLayout } from 'layouts/DashboardLayout';
import { Product as ProductModel } from 'models/Product';
import { OfficeService } from 'services/OfficeService';
import { ProductView } from 'views/ProductView';
import { LoadingIcon } from 'components/LoadingIcon';
import { Paginator } from 'components/Paginator';
import { Button } from 'components/Button';
import { Size } from 'models/UI';
import { Search } from 'components/product/Search';
import { Product } from 'components/product/Product';

export const Products = () => {
  const view = useView(ProductView);
  const [initialProductsRetrieved, setInitialProductsRetrieved] =
    useState(false);
  const officeService = container.resolve(OfficeService);
  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    view.clearProducts();
  }, []);

  useEffect(() => {
    view.getProviders();
    view.getProducts();
  }, []);

  const requiredPermission = () => {
    return `${officeService.getOfficePermissionName()}_PRODUCT_READ`;
  };

  return (
    <DashboardLayout permission={requiredPermission()}>
      <DashboardHeading
        heading="Products"
        breadcrumbs={[
          { title: 'Home', link: '/dashboard' },
          { title: 'Products', link: '/dashboard/products' },
        ]}
        buttons={
          <div className="flex">
            <div className="mr-2">
              <Button
                size={Size.Medium}
                colour="blue"
                icon="SearchIcon"
                text="Search"
                onClick={() => setSearchOpen(true)}
              />
            </div>
          </div>
        }
      />
      <Search open={searchOpen} setOpen={setSearchOpen} />
      {console.log(view.state.searchedProducts)}
      {view.state.searchedProducts?.items ? (
        <>
          {view.state.searchedProducts?.items.length > 0 ? (
            <div>
              <p className="text-sm text-gray-700 mt-2 flex my-auto">
                <SearchIcon className="h-5 mr-2" /> Showing results for Search:{' '}
                {view.state.searchQuery}
              </p>
              <div className="bg-white -mt-14">
                <div className="mx-auto sm:py-24 ">
                  <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                    {view.state.searchedProducts?.items.map(
                      (product: ProductModel) => (
                        <Product product={product} />
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-2">
              <>No Products in Search</>
            </div>
          )}
        </>
      ) : (
        <>
          {view.state.productsLoading ? (
            <div className="h-screen w-full flex items-center justify-center -mt-32">
              <LoadingIcon colour="black" height="12" width="15" />
            </div>
          ) : (
            <>
              <div className="mt-4">
                <Paginator
                  page={view.state.products.page}
                  total={view.state.products.total}
                  numberOnPage={view.state.products.size}
                  previousCallback={() => {
                    view.getProducts(view.state.products.page - 1);
                  }}
                  nextCallback={() =>
                    view.getProducts(view.state.products.page + 1)
                  }
                  hasBorder={false}
                />
              </div>
              {view.state.products?.data.length > 0 ? (
                <div>
                  <div className="bg-white -mt-14">
                    <div className="mx-auto sm:py-24 ">
                      <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                        {view.state.products.data.map(
                          (product: ProductModel) => (
                            <Product product={product} />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-2">
                  <>No Products</>
                </div>
              )}
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};
