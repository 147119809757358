import { singleton } from 'tsyringe';
import { View } from 'views/View';
import {
  GroupedUpdateOrder,
  Order,
  ReportError,
  ShippingUpdateType,
  UpdateOrder,
  UpdateOrderItem,
  UpdateOrderRow,
  UpdateOrderType,
} from 'models/Reports';
import { createSelector } from 'reselect';
import { shippingActions, shippingSelector } from 'stores/shipping';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import moment from 'moment';
import { REPORT_DATE_FORMAT } from 'constants/Reports';

@singleton()
export class EditorView extends View {
  state!: {
    trackingReport: Array<Order>;
    updateReport: Array<GroupedUpdateOrder>;
    updateReportType: ShippingUpdateType;
    reportError: ReportError;
  };

  submitUpdateReport = (values: FormikValues) => {
    this.store.dispatch(
      shippingActions.SubmitShippingUpdateTask({
        shippingUpdate: values as Array<UpdateOrder>,
      })
    );
  };

  submitTrackingReport = (values: FormikValues) => {
    this.store.dispatch(
      shippingActions.SubmitShippingTrackingTask({
        shippingTracking: values as Array<Order>,
      })
    );
  };

  get updateReportInitialValues() {
    const initialValues: Array<UpdateOrder> = [];

    if (this.state.updateReport) {
      this.state.updateReport.map((groupedUpdateOrder: GroupedUpdateOrder) => {
        initialValues.push({
          orderId: groupedUpdateOrder.orderNumber,
          updates: [
            {
              type: this.state.updateReportType
                .toUpperCase()
                .split(' ')
                .join('_'),
              items: groupedUpdateOrder.updateOrderRows.map(
                (updateOrderRow: UpdateOrderRow) => {
                  return {
                    description: updateOrderRow.description,
                    date: moment(
                      updateOrderRow.date,
                      REPORT_DATE_FORMAT
                    ).format(REPORT_DATE_FORMAT),
                    status: updateOrderRow.status,
                    parcelNumber: updateOrderRow.parcelNumber,
                  } as UpdateOrderItem;
                }
              ),
            } as UpdateOrderType,
          ],
        } as UpdateOrder);
      });
    }

    return initialValues;
  }

  updateReportValidationSchema = () => {
    return Yup.array().of(
      Yup.object().shape({
        orderId: Yup.string().required('Required'),
        updates: Yup.array().of(
          Yup.object().shape({
            type: Yup.string().required('Required'),
            items: Yup.array().of(
              Yup.object().shape({
                description: Yup.string().required('Required'),
                date: Yup.string(),
                status: Yup.string(),
                parcelNumber: Yup.string(),
              })
            ),
          })
        ),
      })
    );
  };

  trackingReportInitialValues = () => {
    return this.state.trackingReport;
  };

  trackingReportValidationSchema = () => {
    return Yup.array().of(
      Yup.object().shape({
        orderId: Yup.string().required('Required'),
        items: Yup.array().of(
          Yup.object().shape({
            title: Yup.string().required('Required'),
            label: Yup.string().required('Required'),
            itemCount: Yup.number().required('Required'),
            subtotal: Yup.number().required('Required'),
            tax: Yup.number().required('Required'),
            trackingCode: Yup.string().required('Required'),
            trackingLink: Yup.string().required('Required'),
            weight: Yup.number().required('Required'),
          })
        ),
      })
    );
  };

  clearTrackingReport = () => {
    this.store.dispatch(shippingActions.ClearTrackingReport());
  };

  clearUpdateReport = () => {
    this.store.dispatch(shippingActions.ClearUpdateReport());
  };

  protected stateMapper = createSelector(shippingSelector, (shipping) => {
    return {
      trackingReport: shipping.trackingReport,
      reportError: shipping.reportError,
      updateReport: shipping.updateReport,
      updateReportType: shipping.updateReportType,
    };
  });
}
