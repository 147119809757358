interface Props {
  page: number;
  total: number;
  numberOnPage: number;
  previousCallback: any;
  nextCallback: any;
  hasBorder?: boolean;
}

export const Paginator = ({
  page,
  total,
  numberOnPage,
  previousCallback,
  nextCallback,
  hasBorder = true,
}: Props) => {
  return (
    <nav
      className={`bg-white px-4 py-3 flex items-center justify-between ${
        hasBorder ? 'border-t border-gray-200' : ''
      }  sm:px-6`}
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{page * 10}</span> to{' '}
          <span className="font-medium">{page * 10 + numberOnPage}</span> of{' '}
          <span className="font-medium">{total}</span> results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        {page != 0 && (
          <a
            onClick={() => previousCallback()}
            className="cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Previous
          </a>
        )}
        {total > page * 10 + numberOnPage && (
          <a
            onClick={() => nextCallback()}
            className="cursor-pointer ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          >
            Next
          </a>
        )}
      </div>
    </nav>
  );
};
