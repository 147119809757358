import { injectable } from 'tsyringe';

@injectable()
export class OfficeService {
  private readonly storage: Storage;
  private readonly OFFICE_KEY: string = 'OFFICE';
  private readonly OFFICES = [
    'Albany Supplies',
    'Albany Supplies UK',
    'Office Spot',
  ];
  private readonly OFFICE_API_NAMES = {
    'Albany Supplies': 'albany',
    'Albany Supplies UK': 'albanyuk',
    'Office Spot': 'officespot',
  };
  private readonly OFFICE_PERMISSION_NAMES = {
    'Albany Supplies': 'ALBANY',
    'Albany Supplies UK': 'ALBANY_UK',
    'Office Spot': 'OFFICE_SPOT',
  };

  constructor() {
    this.storage = window?.localStorage;
    if (!this.getOffice()) {
      this.setOffice(this.OFFICES[0]);
    }
  }

  getOfficePermissionName = () => {
    const officeAPIName = Object.entries(this.OFFICE_PERMISSION_NAMES).filter(
      ([key, _]) => key === this.getOffice()
    );
    return officeAPIName[0][1];
  };

  getOfficeAPIName = (): string => {
    const officeAPIName = Object.entries(this.OFFICE_PERMISSION_NAMES).filter(
      ([key, _]) => key === this.getOffice()
    );
    return officeAPIName[0][1].toUpperCase();
  };

  setOffice = (office: string) => {
    this.storage.setItem(this.OFFICE_KEY, office);
  };

  getOffice = () => {
    return this.storage.getItem(this.OFFICE_KEY);
  };

  unSelectedOffices = () => {
    return this.OFFICES.filter((office: string) => office !== this.getOffice());
  };
}
