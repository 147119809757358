import { UploadIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import Dropzone, { DropzoneRootProps } from 'react-dropzone';

interface Props {
  subtitle: string;
  onUpload: (file: File) => void;
}

export const FileUpload = ({ subtitle, onUpload }: Props) => {
  const [file, setFile] = useState<File | null>();

  return (
    <Dropzone
      onDrop={(acceptedFiles) => {
        setFile(acceptedFiles[0]);
        onUpload(acceptedFiles[0]);
      }}
    >
      {({ getRootProps, getInputProps }: DropzoneRootProps) => (
        <section>
          <div className="sm:items-start sm:pt-5 w-full" {...getRootProps()}>
            <div className="mt-1 sm:mt-0">
              <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <UploadIcon className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600 justify-center">
                    {!file ? (
                      <>
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                        >
                          <span>Upload a file</span>
                          <input {...getInputProps()} />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </>
                    ) : (
                      <div className="text-center">{file.name}</div>
                    )}
                  </div>
                  <p className="text-xs text-gray-500">{subtitle}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </Dropzone>
  );
};
