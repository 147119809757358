import { FieldArray, Formik } from 'formik';
import { useView } from 'hooks/useView';
import {
  ShippingUpdateType,
  UpdateOrder,
  UpdateOrderItem,
} from 'models/Reports';
import { Size } from 'models/UI';
import { EditorView } from 'views/EditorView';
import { Button } from 'components/Button';
import { Input } from 'components/Input';

interface Props {
  updateType: ShippingUpdateType;
}

export const UpdateForm = ({ updateType }: Props) => {
  const view = useView(EditorView);

  return (
    <Formik
      initialValues={view.updateReportInitialValues}
      validationSchema={view.updateReportValidationSchema}
      onSubmit={(values) => {
        view.submitUpdateReport(values);
      }}
      enableReinitialize
    >
      {({ errors, handleSubmit, setFieldValue, values }) => (
        <form
          className="space-y-6"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        >
          <div className="mt-6">
            <FieldArray
              name={``}
              validateOnChange={true}
              render={(arrayHelpers) => (
                <>
                  {console.log(values)}
                  {values.map(
                    (updateOrder: UpdateOrder, updateOrderIndex: number) => {
                      return (
                        <div className="mb-10">
                          <div className="relative">
                            <div
                              className="absolute inset-0 flex items-center"
                              aria-hidden="true"
                            >
                              <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-start">
                              <span className="pr-3 bg-white text-lg font-medium text-gray-900">
                                {updateOrder.orderId}
                              </span>
                            </div>
                          </div>
                          <FieldArray
                            name={`${updateOrderIndex}.updates.0.items`}
                            validateOnChange={true}
                            render={(arrayHelpers) => (
                              <>
                                {updateOrder.updates[0].items.map(
                                  (
                                    updateOrderItem: UpdateOrderItem,
                                    index: number
                                  ) => {
                                    return (
                                      <div className="flex w-full mt-5">
                                        <div className="mr-5 flex-grow">
                                          <Input
                                            id="description"
                                            type="text"
                                            name={`${updateOrderIndex}.updates.0.items.${index}.description`}
                                            label="Description"
                                            errors={errors}
                                          />
                                        </div>
                                        <div className="mr-5 flex-grow">
                                          <Input
                                            id="status"
                                            type="text"
                                            name={`${updateOrderIndex}.updates.0.items.${index}.status`}
                                            label="Status"
                                            errors={errors}
                                          />
                                        </div>
                                        <div className="mr-5 flex-grow">
                                          <Input
                                            id="date"
                                            type="text"
                                            name={`${updateOrderIndex}.updates.0.items.${index}.date`}
                                            label="Date"
                                            errors={errors}
                                          />
                                        </div>
                                        {updateOrderItem.parcelNumber && (
                                          <div className="mr-5 flex-grow">
                                            <Input
                                              id="parcelNUmber"
                                              type="text"
                                              name={`${updateOrderIndex}.updates.0.items.${index}.parcelNumber`}
                                              label="Parcel Number"
                                              errors={errors}
                                            />
                                          </div>
                                        )}
                                        <div className="my-auto">
                                          <label
                                            htmlFor={''}
                                            className="block text-sm font-medium text-gray-700"
                                          >
                                            &nbsp;
                                          </label>
                                          <Button
                                            colour="red"
                                            size={Size.Medium}
                                            onClick={() => {
                                              arrayHelpers.remove(index);
                                            }}
                                            text="Delete"
                                            icon="TrashIcon"
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </>
                            )}
                          />
                        </div>
                      );
                    }
                  )}

                  {/*values.map((order: Order, orderIndex: number) => {
                    return (
                      <div className="mb-10">
                        <div className="relative">
                          <div
                            className="absolute inset-0 flex items-center"
                            aria-hidden="true"
                          >
                            <div className="w-full border-t border-gray-300" />
                          </div>
                          <div className="relative flex justify-start">
                            <span className="pr-3 bg-white text-lg font-medium text-gray-900">
                              {order.orderNumber}
                            </span>
                          </div>
                        </div>
                        <OrderItems
                          orderIndex={orderIndex}
                          order={order}
                          errors={errors}
                        />
                      </div>
                    );
                  })*/}
                </>
              )}
            />
          </div>
          <div className="-mt-20 flex">
            <div className="mr-2">
              <Button
                colour="blue"
                size={Size.Medium}
                onClick={() => handleSubmit()}
                text="Submit"
                icon="ArrowRightIcon"
              />
            </div>
            <div className="">
              <Button
                colour="gray"
                size={Size.Medium}
                onClick={() => {
                  view.clearUpdateReport();
                }}
                text="Clear Report"
                icon="XIcon"
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
