export const getErrorFormElement = (name: string, errorObject: any) => {
  try {
    const splitName = name.split('.');

    splitName.map((key, _) => {
      const lookupKey = !isNaN(parseInt(key)) ? parseInt(key) : key;
      if (typeof errorObject[lookupKey] === 'string') {
        return errorObject;
      }
      errorObject = errorObject[lookupKey];
    });
    return errorObject;
  } catch (e) {
    // Undefined. Whatever way this particular error was checked, it doesn't exist in any case
    return undefined;
  }
};
