import { PhotographIcon, QrcodeIcon } from '@heroicons/react/solid';
import { Product as ProductModel, ProductProvider } from 'models/Product';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getCurrencyCode } from 'utils/Price';

interface Props {
  product: ProductModel;
}

export const Product = ({ product }: Props) => {
  const [productFromProvider, setProductFromProvider] =
    useState<ProductProvider>();

  useEffect(() => {
    setProductFromProvider(product.providers[0]);
  }, []);

  return (
    <Link to={`/dashboard/products/${product.id}`}>
      <div key={product.id} className="group relative">
        <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
          {productFromProvider?.update.details.imageUrl ? (
            <img
              src={productFromProvider.update.details.imageUrl}
              alt="alt"
              className="w-full h-full object-center object-cover lg:w-full lg:h-full"
            />
          ) : (
            <div className="flex justify-center items-center h-full">
              <PhotographIcon className="h-32 w-32 text-gray-500" />
            </div>
          )}
        </div>
        <div className="mt-4 flex justify-between">
          <div>
            <h3 className="text-sm text-gray-700">
              <Link to={`/dashboard/products/${product.id}`}>
                <span aria-hidden="true" className="absolute inset-0" />
                {productFromProvider?.update.details.brand &&
                  `${productFromProvider?.update.details.brand} - `}
                {productFromProvider?.update.details.title ||
                  'No title available'}
              </Link>
            </h3>
            <div className="flex my-auto -ml-0.5">
              <QrcodeIcon className="text-gray-500 mr-1 h-5 w-5" />
              <p className="text-sm text-gray-500">
                {productFromProvider?.sku}
              </p>
            </div>
          </div>
          <p className="text-sm font-medium text-gray-900">
            {productFromProvider?.update.price.price
              ? `${getCurrencyCode(productFromProvider.update.price.currency)}${
                  productFromProvider.update.price.price
                }`
              : 'No price available'}
          </p>
        </div>
      </div>
    </Link>
  );
};
