import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useView } from 'hooks/useView';
import { Size } from 'models/UI';
import { ShippingTrackingView } from 'views/ShippingTrackingView';
import { Button } from 'components/Button';
import { FileUpload } from 'components/FileUpload';
import { Modal } from 'components/Modal';

export const UploadTrackingFiles = () => {
  const view = useView(ShippingTrackingView);
  const [pdirReport, setPDIRReport] = useState<File | null>();
  const [carrierReport, setCarrierReport] = useState<File | null>();
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  useEffect(() => {
    if (view.state.reportError) {
      setErrorModalOpen(true);
    }
  }, [view.state.reportError]);

  return (
    <>
      <Modal
        title="Report Error"
        isOpen={errorModalOpen}
        setOpen={setErrorModalOpen}
      >
        {view.state.reportError && (
          <>
            {view.state.reportError.previousRow && (
              <div className="flex bg-green-400 text-white pr-2">
                <div className="mr-1 bg-green-600 w-2/12 text-center">
                  Line #{view.state.reportError.previousRow.number}
                </div>
                <code>
                  {view.state.reportError.previousRow.content.join(',')}
                </code>
              </div>
            )}
            <div className="flex bg-red-400 text-white pr-2">
              <div className="mr-1 bg-red-600 w-2/12 text-center">
                Line #{view.state.reportError.currentRow.number}
              </div>
              <code>
                {view.state.reportError.currentRow.content.join(',')} &lt;-----
                Error
              </code>
            </div>
            {view.state.reportError.nextRow && (
              <div className="flex bg-green-400 text-white pr-2">
                <div className="mr-1 bg-green-600 w-2/12 text-center">
                  Line #{view.state.reportError.nextRow.number}
                </div>
                <code>{view.state.reportError.nextRow.content.join(',')}</code>
              </div>
            )}
          </>
        )}
      </Modal>
      <div className="mt-4 prose prose-sm text-gray-500">
        Take a PDIR report and a Tracking Report and send customers their
        Shipping Tracking links.
      </div>

      <div className="flex">
        <div className="w-1/2 pr-4">
          <FileUpload
            subtitle="CSV PDIR Report"
            onUpload={(file: File) => setPDIRReport(file)}
          />
        </div>
        <div className="w-1/2 pl-4">
          <FileUpload
            subtitle="CSV Tracking Report"
            onUpload={(file: File) => setCarrierReport(file)}
          />
        </div>
      </div>
      <div className="mt-4">
        <Button
          colour="blue"
          size={Size.Medium}
          onClick={() => {
            if (pdirReport && carrierReport) {
              view.submitReports(pdirReport, carrierReport);
            } else {
              toast.error('You must upload both a PDIR and Carrier Report.');
            }
          }}
          text="Submit"
          icon="ArrowRightIcon"
        />
      </div>
    </>
  );
};
