import { DashboardHeading } from 'components/DashboardHeading';
import { UploadTrackingFiles } from 'components/reports/UploadTrackingFiles';
import { useView } from 'hooks/useView';
import { DashboardLayout } from 'layouts/DashboardLayout';
import { TrackingForm } from 'components/forms/TrackingForm';
import { ShippingTrackingView } from 'views/ShippingTrackingView';
import { TasksTable } from 'components/tasks/TasksTable';
import { Paginator } from 'components/Paginator';
import { OfficeService } from 'services/OfficeService';
import { container } from 'tsyringe';

export const ShippingTracking = () => {
  const view = useView(ShippingTrackingView);
  const officeService = container.resolve(OfficeService);

  const requiredPermission = () => {
    return `${officeService.getOfficePermissionName()}_TASK_READ`;
  };

  return (
    <DashboardLayout permission={requiredPermission()}>
      <DashboardHeading
        heading="Shipping Tracking"
        breadcrumbs={[
          { title: 'Home', link: '/dashboard' },
          { title: 'Shipping Tracking', link: '/dashboard/shipping/tracking' },
        ]}
      />
      {view.state.trackingReport && !view.state.reportError ? (
        <TrackingForm />
      ) : (
        <>
          <UploadTrackingFiles />
          <div className="mt-16 mb-10">
            <DashboardHeading heading="Tasks" />
            {view.state.shippingTrackingTasks?.data.length > 0 ? (
              <>
                <div className="flex flex-col mt-5">
                  <div className="-my-2 sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="border-b">
                        <TasksTable
                          tasks={view.state.shippingTrackingTasks.data}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Paginator
                  total={view.state.shippingTrackingTasks.total}
                  page={view.state.shippingTrackingTasks.page}
                  numberOnPage={view.state.shippingTrackingTasks.data.length}
                  previousCallback={() =>
                    view.getShippingTrackingTasks(
                      view.state.shippingTrackingTasks.page - 1
                    )
                  }
                  nextCallback={() =>
                    view.getShippingTrackingTasks(
                      view.state.shippingTrackingTasks.page + 1
                    )
                  }
                />
              </>
            ) : (
              <div className="my-10">
                <div className="-mt-6 prose prose-sm text-gray-500">
                  There are no Shipping Trackings Tasks yet. Any new tasks will
                  appear here.
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </DashboardLayout>
  );
};
