import { useEffect, useState } from 'react';
import { Button } from 'components/Button';
import { DashboardHeading } from 'components/DashboardHeading';
import { RoleForm } from 'components/forms/RoleForm';
import { UserForm } from 'components/forms/UserForm';
import { UserRoleSelector } from 'components/forms/UserRoleSelector';
import { Modal } from 'components/Modal';
import { RolesTab } from 'components/users/RolesTab';
import { UsersTab } from 'components/users/UsersTab';
import { useView } from 'hooks/useView';
import { DashboardLayout } from 'layouts/DashboardLayout';
import { Size } from 'models/UI';
import { classNames } from 'utils/styles';
import { UserView } from 'views/UserView';

const tabs = [
  { name: 'Users', href: 'users', component: <UsersTab /> },
  { name: 'Roles', href: 'roles', component: <RolesTab /> },
];

export const Users = () => {
  const view = useView(UserView);
  const [currentTab, setCurrentTab] = useState<number>(0);

  useEffect(() => {
    if (!view.state.roleActionModalOpen) {
      setTimeout(() => view.clearRole(), 250);
    }
  }, [view.state.roleActionModalOpen]);

  return (
    <DashboardLayout permission="USER_READ">
      <DashboardHeading
        heading="Users"
        breadcrumbs={[
          { title: 'Home', link: '/dashboard' },
          { title: 'Users', link: '/dashboard/users' },
        ]}
        buttons={
          <div className="flex">
            <div className="mr-2">
              <Button
                size={Size.Medium}
                colour="blue"
                icon="PlusIcon"
                text="Create User"
                onClick={() => view.setUserActionModalOpen(true)}
              />
            </div>
            <div className="">
              <Button
                size={Size.Medium}
                colour="blue"
                icon="PlusIcon"
                text="Create Role"
                onClick={() => view.setRoleActionModalOpen(true)}
              />
            </div>
          </div>
        }
      />
      <Modal
        title={view.state.user ? 'Edit User' : 'Create User'}
        isOpen={view.state.userActionModalOpen}
        setOpen={view.setUserActionModalOpen}
      >
        <UserForm />
      </Modal>
      <Modal
        title={view.state.role ? 'Edit Role' : 'Create Role'}
        isOpen={view.state.roleActionModalOpen}
        setOpen={view.setRoleActionModalOpen}
      >
        <RoleForm />
      </Modal>
      {view.state.user && (
        <Modal
          title={`Modify ${view.state.user.username}'s Roles`}
          isOpen={view.state.userRoleActionModalOpen}
          setOpen={view.setUserRoleActionModalOpen}
        >
          <UserRoleSelector user={view.state.user} />
        </Modal>
      )}
      <div className="-mt-2">
        <main className="flex-1">
          <div className="relative mx-auto md:px-8 xl:px-0">
            <div className="pb-16">
              <div className="px-4 sm:px-6 md:px-0">
                <div className="py-6">
                  {/* Tabs */}
                  <div className="lg:hidden">
                    <label htmlFor="selected-tab" className="sr-only">
                      Select a tab
                    </label>
                    <select
                      id="selected-tab"
                      name="selected-tab"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                      defaultValue={'Users'}
                    >
                      {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden lg:block">
                    <div className="border-b border-gray-200">
                      <nav className="-mb-px flex space-x-8">
                        {tabs.map((tab, index) => (
                          <span
                            key={tab.name}
                            onClick={() => setCurrentTab(index)}
                            className={classNames(
                              index === currentTab
                                ? 'border-blue-500 text-blue-600'
                                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                              'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                            )}
                          >
                            {tab.name}
                          </span>
                        ))}
                      </nav>
                    </div>
                  </div>
                  <div className="">{tabs[currentTab].component}</div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </DashboardLayout>
  );
};
