import { singleton } from 'tsyringe';
import { View } from 'views/View';
import { createSelector } from 'reselect';
import { PaginatedResponse } from 'models/Dashboard';
import { dashboardActions, dashboardSelector } from 'stores/dashboard';
import { Role, User } from 'models/Auth';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { PASSWORD_VALIDATION_REGEX } from 'constants/Form';

@singleton()
export class UserView extends View {
  state!: {
    users: PaginatedResponse<User>;
    role: Role;
    user: User;
    roles: PaginatedResponse<Role>;
    userActionModalOpen: boolean;
    userRoleActionModalOpen: boolean;
    roleActionModalOpen: boolean;
  };

  constructor() {
    super();
  }

  userInitialValues = () => {
    return {
      username: '',
      email: '',
      password: '',
    };
  };

  userValidationSchema = (editing: boolean) => {
    const passwordSchema = Yup.string().matches(
      PASSWORD_VALIDATION_REGEX,
      'Password must be between 6-32 characters, have at least 1 uppercase letter, lowercase letter, special character and digit.'
    );

    return Yup.object().shape({
      username: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email').required('Required'),
      password: editing ? passwordSchema : passwordSchema.required('Required'),
    });
  };

  getRole = (id: string) => {
    this.store.dispatch(dashboardActions.GetRole({ id }));
  };

  getUser = (id: string) => {
    this.store.dispatch(dashboardActions.GetUser({ id }));
  };

  clearRole = () => {
    this.store.dispatch(dashboardActions.ClearRole());
  };

  clearUser = () => {
    this.store.dispatch(dashboardActions.ClearUser());
  };

  modifyUserRoles = (roles: Array<Role>) => {
    if (this.state.user.id) {
      const parsedRoles = roles.map((role: Role, index: number) => {
        return role.name;
      });
      this.store.dispatch(
        dashboardActions.AddRolesToUser({
          userId: this.state.user.id,
          roles: parsedRoles,
        })
      );
    }
  };

  roleInitialValues = () => {
    return {
      name: '',
      permissions: [],
    };
  };

  roleValidationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required('Required'),
      permissions: Yup.array(),
    });
  };

  createRole = (values: FormikValues) => {
    this.store.dispatch(dashboardActions.CreateRole({ role: values as Role }));
  };

  editRole = (values: FormikValues) => {
    this.store.dispatch(
      dashboardActions.EditRole({
        id: this.state.role.id,
        role: values as Role,
      })
    );
  };

  editUser = (values: FormikValues) => {
    if (this.state.user.id) {
      this.store.dispatch(
        dashboardActions.EditUser({
          id: this.state.user.id,
          user: values as User,
        })
      );
    }
  };

  createUser = (values: FormikValues) => {
    this.store.dispatch(dashboardActions.CreateUser({ user: values as User }));
  };

  getUsers = (page?: number) => {
    this.store.dispatch(dashboardActions.GetUsers({ page }));
  };

  getRoles = (page?: number) => {
    this.store.dispatch(dashboardActions.GetRoles({ page }));
  };

  setUserActionModalOpen = (userActionModalOpen: boolean) => {
    this.store.dispatch(
      dashboardActions.SetUserActionModalOpen({ userActionModalOpen })
    );
  };

  setUserRoleActionModalOpen = (userRoleActionModalOpen: boolean) => {
    this.store.dispatch(
      dashboardActions.SetUserRoleActionModalOpen({ userRoleActionModalOpen })
    );
  };

  setRoleActionModalOpen = (roleActionModalOpen: boolean) => {
    this.store.dispatch(
      dashboardActions.SetRoleActionModalOpen({ roleActionModalOpen })
    );
  };

  deleteRole = (id: string) => {
    this.store.dispatch(dashboardActions.DeleteRole({ id }));
  };

  deleteUser = (id: string) => {
    this.store.dispatch(dashboardActions.DeleteUser({ id }));
  };

  protected connected = () => {
    this.getUsers();
    this.getRoles();
  };

  protected stateMapper = createSelector(dashboardSelector, (dashboard) => {
    return {
      users: dashboard.users,
      roles: dashboard.roles,
      user: dashboard.user,
      role: dashboard.role,
      userActionModalOpen: dashboard.userActionModalOpen,
      userRoleActionModalOpen: dashboard.userRoleActionModalOpen,
      roleActionModalOpen: dashboard.roleActionModalOpen,
    };
  });
}
