import { useView } from 'hooks/useView';
import { Role as RoleModel } from 'models/Auth';
import { UserView } from 'views/UserView';
import { EmptyState } from 'components/EmptyState';
import { Paginator } from 'components/Paginator';
import { Role } from './Role';

export const RolesTab = () => {
  const view = useView(UserView);
  return (
    <>
      {view.state.roles?.data.length > 0 ? (
        <>
          <div className="flex flex-col">
            <div className="-my-2 sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="border-b">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {view.state.roles.data.map(
                        (role: RoleModel, index: number) => (
                          <Role role={role} />
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Paginator
            total={view.state.roles.total}
            page={view.state.roles.page}
            numberOnPage={view.state.roles.data.length}
            previousCallback={() => view.getRoles(view.state.roles.page - 1)}
            nextCallback={() => view.getRoles(view.state.roles.page + 1)}
          />
        </>
      ) : (
        <div className="my-10">
          <EmptyState
            object="Role"
            colour="blue"
            onClick={() => console.log('do nothing')}
          />
        </div>
      )}
    </>
  );
};
