import axios, { AxiosRequestConfig } from 'axios';
import { container, injectable } from 'tsyringe';
import { Store } from 'redux';
import { State, STORE_TOKEN } from 'stores/store';
import { AuthTokens, Role, User } from 'models/Auth';
import jwtDecode from 'jwt-decode';
import { authActions } from 'stores/auth';
import { NEXT_URL } from 'constants/Auth';

@injectable()
export class AuthService {
  protected store = container.resolve<Store<State>>(STORE_TOKEN);
  private readonly storage: Storage;
  private readonly USER_ID_KEY: string = 'user:id';
  private readonly ACCESS_TOKEN_KEY: string = 'tokens:access';
  private readonly REFRESH_TOKEN_KEY: string = 'tokens:refresh';
  private readonly OPEN_ENDPOINTS = ['/accounts/user/register'];
  private readonly apiUrl: string =
    process.env.REACT_APP_API_URL || 'http://localhost:8080/v1';

  constructor() {
    this.storage = window?.localStorage;

    axios.interceptors.request.use((config: AxiosRequestConfig) => {
      if (config.url) {
        const url = new URL(config.url);
        if (!this.OPEN_ENDPOINTS.includes(url.pathname)) {
          let accessToken = this.storage.getItem(this.ACCESS_TOKEN_KEY);
          config.headers = {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
          };
          config.headers['Content-Type'] = 'application/json';
        }
      }
      return config;
    });

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 403) {
          this.store.dispatch(
            authActions.SetLoginRequired({ loginRequired: true })
          );
        }
        if (error.response.status !== 401) {
          return Promise.reject(error);
        }
        if (error.config.url.includes('/authorization')) {
          this.store.dispatch(
            authActions.SetLoginRequired({ loginRequired: true })
          );
          this.storage.clear();
          return Promise.reject(error);
        }
        if (error.config.url.includes('/authorization')) {
          return Promise.reject(error);
        }
        return axios
          .put(`${this.apiUrl}/authorization`, {
            refreshToken: this.getRefreshToken(),
          })
          .then((response: any) => {
            this.storage.setItem(this.ACCESS_TOKEN_KEY, response.token);
            error.response.config.headers = {
              Authorization: `Bearer ${response.token}`,
              Accept: 'application/json',
            };
            error.response.config.headers['Content-Type'] =
              'application/json;charset=utf-8';
            return axios(error.response.config);
          })
          .catch((error) => {
            console.log('was an error refreshing the token');
            this.store.dispatch(
              authActions.SetLoginRequired({ loginRequired: true })
            );
            return Promise.reject(error);
          });
      }
    );
  }

  setNextURL = (nextURL: string) => {
    this.storage.setItem(NEXT_URL, nextURL);
  };

  getNextURL = () => {
    this.storage.getItem(NEXT_URL);
  };

  userHasPermission = (user: User, permission: string): boolean => {
    let hasPermission: boolean = false;
    user.roles?.forEach((role: Role) => {
      if (role.permissions.includes(permission)) {
        hasPermission = true;
        return;
      }
    });
    return hasPermission;
  };

  requireLogin = () => {
    return (
      this.storage.getItem(this.ACCESS_TOKEN_KEY) === null ||
      this.storage.getItem(this.REFRESH_TOKEN_KEY) === null
    );
  };

  isLoggedIn = () => {
    if (this.storage.getItem(this.ACCESS_TOKEN_KEY)) {
      try {
        const decodedToken = jwtDecode(
          this.storage.getItem(this.ACCESS_TOKEN_KEY) || ''
        );
        return true;
      } catch {}
    }
    return false;
  };

  setTokens = (tokens: AuthTokens) => {
    this.storage.setItem(this.ACCESS_TOKEN_KEY, tokens.access);
    this.storage.setItem(this.REFRESH_TOKEN_KEY, tokens.refresh);
  };

  setUserId = (userId: string) => {
    this.storage.setItem(this.USER_ID_KEY, userId);
  };

  getUserId = (): string => {
    return this.storage.getItem(this.USER_ID_KEY) || '';
  };

  getRefreshToken = (): string => {
    return this.storage.getItem(this.REFRESH_TOKEN_KEY) || '';
  };

  logout = async () => {
    await this.storage.clear();
    this.store.dispatch(authActions.ResetState({}));
  };
}
