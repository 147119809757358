import { PlusIcon } from '@heroicons/react/outline';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { LoadingIcon } from 'components/LoadingIcon';
import { Formik, FormikValues } from 'formik';
import { useView } from 'hooks/useView';
import { Identifier as IdentifierModel } from 'models/Product';
import { Size } from 'models/UI';
import { useState } from 'react';
import { ProductView } from 'views/ProductView';

interface Props {
  categoryId: string;
  identifier: IdentifierModel;
}

export const Identifier = ({ categoryId, identifier }: Props) => {
  const view = useView(ProductView);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Formik
        initialValues={identifier}
        validationSchema={view.identifierValidationSchema}
        onSubmit={(values: FormikValues) => {
          view.updateCategoryIdentifier(
            categoryId,
            identifier.id,
            values as IdentifierModel
          );
          setIsLoading(true);
          setIsEditing(false);
          setTimeout(() => setIsLoading(false), 500);
        }}
        enableReinitialize
      >
        {({ handleSubmit, errors }) => (
          <tr key={identifier.id} className="hover:bg-gray-50 border-b">
            <td className="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {isEditing ? (
                <div className="-mt-2">
                  <Input id="value" type="text" errors={errors} name="value" />
                </div>
              ) : (
                <>
                  {isLoading ? <LoadingIcon colour="gray" /> : identifier.value}
                </>
              )}
            </td>
            <td className="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {isEditing ? (
                <div className="-mt-2">
                  <Input id="name" type="text" errors={errors} name="name" />
                </div>
              ) : (
                <>
                  {isLoading ? <LoadingIcon colour="gray" /> : identifier.name}
                </>
              )}
            </td>
            <td
              className="cursor-pointer pl-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex my-auto flex-row-reverse"
              onClick={() => {}}
            >
              <div className="">
                <Button
                  colour="red"
                  size={Size.Small}
                  onClick={() => {
                    view.deleteCategoryIdentifier(categoryId, identifier.id);
                  }}
                  text="Delete"
                  icon="TrashIcon"
                />
              </div>
              <div className="mr-2">
                {isEditing ? (
                  <Button
                    colour="green"
                    size={Size.Small}
                    onClick={() => {
                      handleSubmit();
                    }}
                    text="Save"
                    icon="SaveIcon"
                  />
                ) : (
                  <Button
                    colour="blue"
                    size={Size.Small}
                    onClick={() => {
                      setIsEditing(true);
                    }}
                    text="Edit"
                    icon="PencilIcon"
                  />
                )}
              </div>
            </td>
          </tr>
        )}
      </Formik>
    </>
  );
};
