import 'reflect-metadata';
import 'styles/main.css';
import { Login } from 'pages/Login';
import { Dashboard } from 'pages/Dashboard';
import { ShippingTracking } from 'pages/shipping/Tracking';
import { ShippingUpdates } from 'pages/shipping/Updates';
import { Users } from 'pages/Users';
import { registerServices } from 'bootstrap';
import { Toaster } from 'react-hot-toast';
import { Products } from 'pages/products/Products';
import { Product } from 'pages/products/Product';
import { Route, Routes } from 'react-router';
import { Profile } from 'pages/Profile';
import { NotFound } from 'pages/NotFound';
import { Logout } from 'pages/Logout';
import { NoPermission } from 'pages/NoPermission';
import { Categories } from 'pages/products/categories/Categories';

registerServices();

function App() {
  return (
    <>
      <Toaster />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/dashboard/users" element={<Users />} />
        <Route path="/dashboard/profile" element={<Profile />} />
        <Route path="/dashboard/products/:productId" element={<Product />} />
        <Route path="/dashboard/products" element={<Products />} />
        <Route path="/dashboard/products/categories" element={<Categories />} />
        <Route
          path="/dashboard/shipping/tracking"
          element={<ShippingTracking />}
        />
        <Route
          path="/dashboard/shipping/updates"
          element={<ShippingUpdates />}
        />
        <Route path="/" element={<Dashboard />} />
        <Route path="/dashboard/no-permission" element={<NoPermission />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
