import { Formik, FormikValues } from 'formik';
import { useState } from 'react';
import { useView } from 'hooks/useView';
import { UserView } from 'views/UserView';
import { Input } from 'components/Input';
import { ModalSubmitButton } from 'components/ModalSubmitButton';
import { NonFormCheckbox } from 'components/NonFormCheckbox';

export const UserForm = () => {
  const view = useView(UserView);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Formik
      initialValues={view.state.user ? view.state.user : view.userInitialValues}
      validationSchema={() => view.userValidationSchema(!!view.state.user)}
      onSubmit={(values: FormikValues) => {
        if (view.state.user) {
          view.editUser(values);
        } else {
          view.createUser(values);
        }
      }}
      enableReinitialize
    >
      {({ errors, handleSubmit, setFieldValue, values }) => (
        <form>
          <div className="my-3">
            <Input
              id="username"
              type="text"
              errors={errors}
              name="username"
              label="Username"
            />
          </div>
          <div className="mb-3">
            <Input
              id="email"
              type="text"
              errors={errors}
              name="email"
              label="Email"
            />
          </div>
          <div className="mb-3">
            {view.state.user ? (
              <>
                <div className="mt-5 mb-4 ml-1">
                  <NonFormCheckbox
                    name="changePassword"
                    label="Change Password?"
                    onCheck={() => setShowPassword(true)}
                    onUnCheck={() => setShowPassword(false)}
                  />
                </div>
                {showPassword && (
                  <Input
                    id="password"
                    type="password"
                    errors={errors}
                    name="password"
                    label="Password"
                  />
                )}
              </>
            ) : (
              <Input
                id="password"
                type="password"
                errors={errors}
                name="password"
                label="Password"
              />
            )}
          </div>
          <div className="w-full">
            <ModalSubmitButton
              onSubmit={() => handleSubmit()}
              colour="blue"
              text={`${view.state.user ? 'Edit User' : 'Create User'}`}
            />
          </div>
        </form>
      )}
    </Formik>
  );
};
