import { ChangeEvent } from 'react';

interface Props {
  name: string;
  label?: string;
  isChecked?: boolean;
  onCheck?: () => void;
  onUnCheck?: () => void;
}

export const NonFormCheckbox = ({
  name,
  label,
  isChecked,
  onCheck,
  onUnCheck,
}: Props) => {
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          id={name}
          aria-describedby="candidates-description"
          name={name}
          type="checkbox"
          checked={isChecked}
          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked && onCheck) {
              onCheck();
            } else if (!event.target.checked && onUnCheck) {
              onUnCheck();
            }
          }}
        />
      </div>
      {label && (
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className="font-medium text-gray-700">
            {label}
          </label>
        </div>
      )}
    </div>
  );
};
