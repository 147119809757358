import { TaskStatus } from './Shipping';

export enum ShippingUpdateType {
  BACK_ORDER = 'Back Order',
  BACK_ORDER_RELEASE = 'Back Order Released',
  SHIPPING_ISSUE = 'Shipping Issue',
}

export interface Task {
  id: string;
  date: string;
  type: string;
  status: TaskStatus;
}

export interface OrderItem {
  title: string;
  label: string;
  itemCount: number;
  subtotal: number;
  tax: number;
  trackingCode: string;
  trackingLink: string;
  weight: number;
}

export interface Notification {
  id: string;
  order: Order;
  status: TaskStatus;
  responseData: string;
}

export interface Order {
  orderId: string;
  items: Array<OrderItem>;
}

export interface UpdateOrderItem {
  description: string;
  status: string;
  date: string;
  parcelNumber?: string;
}

export interface UpdateOrderType {
  type: string;
  items: Array<UpdateOrderItem>;
}

export interface UpdateOrder {
  orderId: string;
  updates: Array<UpdateOrderType>;
}

export interface UpdateOrderRow {
  ourOrderNumber: string;
  yourOrderNumber: string;
  description: string;
  status: string;
  date: string;
  parcelNumber?: string;
}

export interface GroupedUpdateOrder {
  orderNumber: string;
  updateOrderRows: Array<UpdateOrderRow>;
}

export interface BackOrderReportRow extends UpdateOrderRow {
  orderDate: string;
  productCode: string;
  quantityOrdered: string;
  quantityOustanding: string;
  requiredDate: string;
  freeStock: string;
  goodsInWaiting: string;
  nextPOQuantity: string;
}

export interface BackOrderReleaseReportRow extends UpdateOrderRow {
  code: string;
  quantity: string;
  orderDate: string;
}

export interface ShippingIssueReportRow extends UpdateOrderRow {
  despatchDate: string;
}

export interface PDIRReportRow {
  pdirNumber: string;
  orderNumber: string;
  sordNumber: string;
  eNumber: string;
}

export interface CarrierReportRow {
  dateCreated: string;
  accountNumber: string;
  orderNumber: string;
  customerName: string;
  customerOrderNumber: string;
  customerSalesOrderNumber: string;
  postcode: string;
  trackingReference: string;
  trackingURL: string;
  productCode: string;
  productDescription: string;
  quantity: string;
  despatchCourier: string;
  calculatedWeight: number;
  carrierID: string;
  deliveryStatus: string;
  buyersLineReference: string;
  customerTrackingReference: string;
}

export interface ReportRow {
  number: number;
  content: Array<string>;
}

export interface ReportError {
  type: string;
  previousRow?: ReportRow;
  currentRow: ReportRow;
  nextRow?: ReportRow;
}
