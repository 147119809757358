import { useState } from 'react';
import { Role as RoleModel } from 'models/Auth';
import { Modal } from 'components/Modal';
import { ActionDropdown } from 'components/ActionDropdown';
import { Menu } from '@headlessui/react';
import { classNames } from 'utils/styles';
import { TrashIcon } from '@heroicons/react/solid';
import { DeleteConfirmation } from 'components/DeleteConfirmation';
import { UserView } from 'views/UserView';
import { useView } from 'hooks/useView';

interface Props {
  role: RoleModel;
}
export const Role = ({ role }: Props) => {
  const view = useView(UserView);
  const [deleteRoleModalOpen, setDeleteRoleModalOpen] = useState(false);

  const getRole = () => {
    view.getRole(role.id);
    view.setRoleActionModalOpen(true);
  };

  return (
    <>
      <tr key={role.id} className="hover:bg-gray-50 border-b">
        <td
          onClick={() => {
            getRole();
          }}
          className="cursor-pointer  px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
        >
          {role.name}
        </td>
        <td className="text-right pr-6">
          <ActionDropdown
            contents={
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={() => view.setRoleActionModalOpen(true)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'group flex items-center px-4 py-2 text-sm cursor-pointer'
                    )}
                  >
                    <TrashIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Delete
                  </a>
                )}
              </Menu.Item>
            }
          />
        </td>
      </tr>
      <Modal isOpen={deleteRoleModalOpen} setOpen={setDeleteRoleModalOpen}>
        <DeleteConfirmation
          title={`Delete ${role.name}`}
          subtitle={`You are about to delete the ${role.name} role, please confirm.`}
          performDelete={() => view.deleteRole(role.id)}
        />
      </Modal>
    </>
  );
};
