import { RadioGroup } from '@headlessui/react';
import {
  ArchiveIcon,
  CheckIcon,
  ClockIcon,
  StarIcon,
} from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { epochToDateTime } from 'utils/Date';
import { getCurrencyCode } from 'utils/Price';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { container } from 'tsyringe';
import { Button } from 'components/Button';
import { DashboardHeading } from 'components/DashboardHeading';
import { useView } from 'hooks/useView';
import { DashboardLayout } from 'layouts/DashboardLayout';
import { Size } from 'models/UI';
import { OfficeService } from 'services/OfficeService';
import { classNames } from 'utils/styles';
import { ProductView } from 'views/ProductView';
import { LoadingIcon } from 'components/LoadingIcon';
import { ProductProvider, Provider } from 'models/Product';
import { Providers } from 'components/product/Providers';

export const Product = () => {
  const view = useView(ProductView);
  const navigate = useNavigate();
  const { productId } = useParams<'productId'>();
  const officeService = container.resolve(OfficeService);
  const [masterProductFromProvider, setMasterProductFromProvider] =
    useState<ProductProvider>();

  useEffect(() => {
    console.log('in here');
    view.clearProduct();
  }, []);

  useEffect(() => {
    if (!view.state.product && productId) {
      view.getProduct(productId);
    }

    if (view.state.product) {
      console.log('got a product, setting master');
      console.log('product is:');
      console.log(view.state.product);
      setMasterProductFromProvider(view.state.product?.providers[0]);
    }
  }, [view.state.product, productId]);

  const requiredPermission = () => {
    return `${officeService.getOfficePermissionName()}_PRODUCT_READ`;
  };

  return (
    <DashboardLayout permission={requiredPermission()}>
      {!masterProductFromProvider ? (
        <div className="flex h-screen justify-center items-center -mt-20">
          <LoadingIcon colour="black" height="10" width="10" />
        </div>
      ) : (
        <>
          <DashboardHeading
            breadcrumbs={[
              { title: 'Home', link: '/dashboard' },
              { title: 'Products', link: '/dashboard/products' },
              {
                title: masterProductFromProvider?.update.details.title || '',
                link: `/dashboard/products/${masterProductFromProvider.id}`,
              },
            ]}
          />
          <main>
            {/* Product */}
            <div className="bg-white">
              <div className="max-w-2xl mx-auto pt-16 pb-24 px-4 sm:pt-12 sm:pb-32 sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
                {/* Product details */}
                <div className="lg:max-w-lg lg:self-end">
                  {/* <div className="mb-10 mt-6">
                    <Button
                      colour="blue"
                      size={Size.Medium}
                      onClick={() => navigate('/dashboard/products')}
                      text="Back to Products"
                      icon="ArrowLeftIcon"
                    />
                  </div> */}
                  {/* <nav aria-label="Breadcrumb">
                    <ol role="list" className="flex items-center space-x-2">
                      {product.breadcrumbs.map((breadcrumb, breadcrumbIdx) => (
                        <li key={breadcrumb.id}>
                          <div className="flex items-center text-sm">
                            <a
                              href={breadcrumb.href}
                              className="font-medium text-gray-500 hover:text-gray-900"
                            >
                              {breadcrumb.name}
                            </a>
                            {breadcrumbIdx !==
                            product.breadcrumbs.length - 1 ? (
                              <svg
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                aria-hidden="true"
                                className="ml-2 flex-shrink-0 h-5 w-5 text-gray-300"
                              >
                                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                              </svg>
                            ) : null}
                          </div>
                        </li>
                      ))}
                    </ol>
                  </nav> */}
                  <div className="mt-4">
                    <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                      {masterProductFromProvider.update.details.title}
                    </h1>
                  </div>
                  <section
                    aria-labelledby="information-heading"
                    className="mt-4"
                  >
                    <h2 id="information-heading" className="sr-only">
                      Product information
                    </h2>

                    <div className="flex items-center">
                      <p className="text-lg text-gray-900 sm:text-xl flex">
                        {getCurrencyCode(
                          masterProductFromProvider.update.price.currency
                        )}
                        {masterProductFromProvider.update.price.price}{' '}
                        <small className="ml-4 mt-1.5 text-sm text-gray-500 flex my-auto">
                          <ClockIcon className="h-5 mr-1.5" />{' '}
                          {epochToDateTime(
                            parseInt(
                              masterProductFromProvider.update.measuredAt
                            )
                          )}
                        </small>
                      </p>
                    </div>

                    <div className="mt-4 space-y-6 overflow-y-scroll h-96">
                      <p className="text-base text-gray-500">
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              masterProductFromProvider.update.details
                                .description,
                          }}
                        />
                      </p>
                    </div>

                    <div className="mt-6 flex items-center">
                      <ArchiveIcon
                        className="flex-shrink-0 w-5 h-5 text-gray-500"
                        aria-hidden="true"
                      />
                      <div className="ml-2 text-sm text-gray-500 flex">
                        <span className="my-auto">
                          {masterProductFromProvider.update.stock.stock} items
                          currently in stock
                        </span>
                        <small className="ml-4 text-sm text-gray-500 flex my-auto">
                          <ClockIcon className="h-5 mr-1.5" />{' '}
                          {epochToDateTime(
                            parseInt(
                              masterProductFromProvider.update.measuredAt
                            )
                          )}
                        </small>
                      </div>
                    </div>
                    <div className="border-t border-gray-200 mt-10 pt-4">
                      <div className="mt-4 prose prose-sm text-gray-500">
                        <ul role="list">
                          <li>
                            <strong>EAN:</strong>{' '}
                            {masterProductFromProvider.ean}
                          </li>
                          <li>
                            <strong>SKU:</strong>{' '}
                            {masterProductFromProvider.sku}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="ml-10  lg:col-start-2 lg:row-span-2">
                  <div className="aspect-w-1 aspect-h-1 rounded-lg overflow-hidden">
                    <img
                      src={masterProductFromProvider.update.details.imageUrl}
                      alt={masterProductFromProvider.update.details.title}
                      className="w-full h-full object-center object-cover"
                    />
                  </div>
                </div>
              </div>
              <section aria-labelledby="options-heading" className="">
                <Providers />
              </section>
            </div>
          </main>
        </>
      )}
    </DashboardLayout>
  );
};
