import { RadioGroup } from '@headlessui/react';
import { useView } from 'hooks/useView';
import {
  ProductProvider,
  Provider as ProviderModel,
  ProviderProductDetails,
} from 'models/Product';
import { useEffect, useState } from 'react';
import { ProductView } from 'views/ProductView';
import { Provider } from './Provider';

export const Providers = () => {
  const view = useView(ProductView);
  const [haveReceivedDetails, setHaveReceivedDetails] = useState(false);

  // todo: this doesn't change when the product changes. Needs to listen to product changes and re-get provider product details
  useEffect(() => {
    view.clearProviderUpdates();
    view.getProviders();
  }, []);

  return (
    <div className="-mt-16">
      {console.log(view.state.product.providers)}
      {view.state.product.providers && (
        <>
          {view.state.product.providers.map(
            (productProvider: ProductProvider) => {
              return <Provider productProvider={productProvider} />;
            }
          )}
        </>
      )}
    </div>
  );
};
