interface Props {
  colour: string;
  value: string;
}
export const Badge = ({ colour, value }: Props) => {
  return (
    <span
      className={`inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-${colour}-100 text-${colour}-800`}
    >
      {value}
    </span>
  );
};
