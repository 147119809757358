import 'reflect-metadata';
import { container } from 'tsyringe';
import { Store } from 'redux';
import { State, STORE_TOKEN } from 'stores/store';

interface Subscription {
  active: boolean;
  callback: () => void;
}

export class View {
  state?: any;
  private _state: any;
  protected store = container.resolve<Store<State>>(STORE_TOKEN);
  private subscriptions: Subscription[] = [];
  private storeUnsubscribe?: () => void;

  constructor() {
    Object.defineProperty(this, 'state', {
      set(value) {
        if (value !== this._state) {
          this._state = value;
          this.triggerUpdate();
        }
      },
      get() {
        return this._state;
      },
    });
  }

  /** @internal **/
  _onStateChange = () => {
    const state = this.store.getState();
    const mapped = this.stateMapper(state);
    if (mapped !== undefined) {
      this.state = mapped;
    }
    this.triggerUpdate();
  };

  /** @internal **/
  _connect(callback: () => void) {
    const subscription = { callback, active: true };
    this.subscriptions.push(subscription);
    const unsubscribe = () => {
      subscription.active = false;
      this.subscriptions = this.subscriptions.filter((s) => s !== subscription);
      if (this.subscriptions.length === 0) {
        this.storeUnsubscribe!();
        this.storeUnsubscribe = undefined;
        this.disconnected();
      }
    };

    if (!this.storeUnsubscribe) {
      this.storeUnsubscribe = this.store.subscribe(this._onStateChange);
      this.connected();
    }

    this._onStateChange();

    return unsubscribe;
  }

  triggerUpdate() {
    setTimeout(() => {
      this.subscriptions.forEach((s) => s.active && s.callback());
    }, 0);
  }

  protected stateMapper(state: State) {}

  protected connected() {}

  protected disconnected() {}
}
