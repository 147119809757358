import { Formik, FormikValues } from 'formik';
import { useView } from 'hooks/useView';
import { LoadingLayout } from 'layouts/LoadingLayout';
import { PERMISSIONS } from 'models/Auth';
import { UserView } from 'views/UserView';
import { Checkbox } from 'components/Checkbox';
import { Input } from 'components/Input';
import { ModalSubmitButton } from 'components/ModalSubmitButton';

export const RoleForm = () => {
  const view = useView(UserView);

  return (
    <LoadingLayout>
      <Formik
        initialValues={
          view.state.role ? view.state.role : view.roleInitialValues
        }
        validationSchema={view.roleValidationSchema}
        onSubmit={(values: FormikValues) => {
          if (view.state.role) {
            view.editRole(values);
          } else {
            view.createRole(values);
          }
        }}
        enableReinitialize
      >
        {({ errors, handleSubmit, setFieldValue, values }) => (
          <form className="mt-3">
            <Input id="name" type="text" errors={[]} name="name" label="Name" />
            <fieldset className="space-y-5">
              <label
                htmlFor="permissions"
                className="block text-sm font-medium text-gray-700 mt-4 -mb-2"
              >
                Permissions
              </label>
              <div className="flex flex-wrap">
                {Object.entries(PERMISSIONS).map(([key, value]) => {
                  return (
                    <div className="w-1/2">
                      <Checkbox name="permissions" value={key} label={value} />
                    </div>
                  );
                })}
              </div>
            </fieldset>
            <div className="w-full">
              <ModalSubmitButton
                onSubmit={() => handleSubmit()}
                colour="blue"
                text={`${view.state.role ? 'Edit Role' : 'Create Role'}`}
              />
            </div>
          </form>
        )}
      </Formik>
    </LoadingLayout>
  );
};
