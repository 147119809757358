import * as HeroIcons from '@heroicons/react/solid';
import { Size } from 'models/UI';

interface Props {
  size: Size;
  colour: string;
  onClick: () => void;
  text?: string;
  icon?: string;
}

const sizeSpecificClasses = {
  SMALL: 'px-3 py-2 leading-4',
  MEDIUM: 'px-4 py-2',
  LARGE: 'px-5 py-2.5',
  EXTRA_LARGE: 'px-6 py-3',
};

const getClasses = (size: Size, colour: string) => {
  const defaultClasses = `inline-flex items-center border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-${colour}-600 hover:bg-${colour}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${colour}-500`;
  return `${defaultClasses} ${sizeSpecificClasses[size]}`;
};

export const Button = ({ size, colour, onClick, text, icon }: Props) => {
  const { ...icons } = HeroIcons;
  // @ts-ignore
  const Icon: JSX.Element = icons[icon];
  return (
    <>
      <button
        type="button"
        className={`${getClasses(size, colour)}`}
        onClick={() => onClick()}
      >
        {text}
        {/* @ts-ignore */}
        <Icon
          className={`${text ? 'ml-2' : ''}  -mr-0.5 h-4 w-4`}
          aria-hidden="true"
        />
      </button>
    </>
  );
};
