import * as HeroIcons from '@heroicons/react/solid';

interface Props {
  colour: string;
  icon: string;
  title: string;
  subtitle?: string;
}
export const Alert = ({ colour, icon, title, subtitle }: Props) => {
  const { ...icons } = HeroIcons;
  // @ts-ignore
  const Icon: JSX.Element = icons[icon];

  return (
    <div className={`rounded-md bg-${colour}-50 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {/* @ts-ignore */}
          <Icon className={`h-5 w-5 text-${colour}-400`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium text-${colour}-800`}>{title}</h3>
          {subtitle && (
            <div className={`mt-2 text-sm text-${colour}-700`}>
              <p>{subtitle}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
