import { createSelector } from '@reduxjs/toolkit';
import { container, singleton } from 'tsyringe';
import { User } from 'models/Auth';
import { AuthService } from 'services/AuthService';
import { authActions, authSelector } from 'stores/auth';
import { View } from 'views/View';

@singleton()
export class ProtectedLayoutView extends View {
  state!: {
    loginRequired: boolean;
    user: User;
  };
  authService = container.resolve(AuthService);

  constructor() {
    super();
    this.store.dispatch(
      authActions.GetUser({ userId: this.authService.getUserId() })
    );
  }

  protected stateMapper = createSelector(authSelector, (auth) => {
    return {
      loginRequired: auth.loginRequired,
      user: auth.user,
    };
  });
}
