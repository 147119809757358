import { TaskStatus } from 'models/Shipping';
import { Badge } from 'components/Badge';
import lodash from 'lodash';

interface Props {
  status: TaskStatus;
}
export const TaskStatusBadge = ({ status }: Props) => {
  return (
    <>
      {status === TaskStatus.PENDING && (
        <Badge
          colour="blue"
          value={lodash.startCase(TaskStatus.PENDING.toLowerCase())}
        />
      )}
      {status === TaskStatus.FAILED && (
        <Badge
          colour="red"
          value={lodash.startCase(TaskStatus.FAILED.toLowerCase())}
        />
      )}
      {status === TaskStatus.COMPLETE && (
        <Badge
          colour="green"
          value={lodash.startCase(TaskStatus.COMPLETE.toLowerCase())}
        />
      )}
      {status === TaskStatus.RETRYABLE && (
        <Badge
          colour="pink"
          value={lodash.startCase(TaskStatus.RETRYABLE.toLowerCase())}
        />
      )}
      {status === TaskStatus.PARTIALLY_COMPLETE && (
        <Badge
          colour="yellow"
          value={lodash.startCase(TaskStatus.PARTIALLY_COMPLETE.toLowerCase())}
        />
      )}
    </>
  );
};
