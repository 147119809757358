import { ProductPrice } from 'models/Product';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { formatProductPriceForChart } from 'utils/Product';

interface Props {
  productPrices: Array<ProductPrice>;
}

export const PriceGraph = ({ productPrices }: Props) => {
  return (
    <>
      <h3 className="text-xl font-semibold text-gray-900">Historical Prices</h3>
      <div className="mt-6 -ml-8">
        <AreaChart
          width={500}
          height={400}
          data={formatProductPriceForChart(productPrices)}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="price"
            stroke="#8884d8"
            fill="#8884d8"
          />
        </AreaChart>
      </div>
    </>
  );
};
