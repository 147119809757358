import { ArrowRightIcon, PlusIcon } from '@heroicons/react/solid';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { Formik, FormikValues } from 'formik';
import { useView } from 'hooks/useView';
import { Identifier } from 'models/Product';
import { Size } from 'models/UI';
import { ProductView } from 'views/ProductView';

interface Props {
  categoryId: string;
}

export const CreateIdentifier = ({ categoryId }: Props) => {
  const view = useView(ProductView);

  return (
    <Formik
      initialValues={{
        name: '',
        value: '',
      }}
      validationSchema={view.identifierValidationSchema}
      onSubmit={(values: FormikValues) => {
        view.createCategoryIdentifier(categoryId, values as Identifier);
      }}
      enableReinitialize
    >
      {({ handleSubmit, errors }) => (
        <>
          <div className="flex p-3">
            <div className="flex w-2/3">
              <div className="w-1/2 mr-4">
                <Input
                  id="value"
                  type="text"
                  errors={errors}
                  name="value"
                  placeholder="Value"
                />
              </div>
              <div className="w-1/2">
                <Input
                  id="name"
                  type="text"
                  errors={errors}
                  name="name"
                  placeholder="Name"
                />
              </div>
            </div>
            <div className="ml-4 my-auto pt-1 w-1/3">
              <button
                type="button"
                onClick={() => handleSubmit()}
                className={`w-full group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
              >
                <span className="absolute right-0 inset-y-0 flex items-center pr-3">
                  <ArrowRightIcon
                    className={`h-5 w-5 text-green-500 group-hover:text-green-400`}
                    aria-hidden="true"
                  />
                </span>
                Add Identifier
              </button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
