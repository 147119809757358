import { PaginatedResponse } from './Dashboard';

export interface ProductDetails {
  id: string;
  title: string;
  brand: string;
  description: string;
  detailedDescription: string;
  imageUrl: string;
  thumbnailUrl: string;
  weight: string;
}

export interface ProductStock {
  id: string;
  stock: number;
}

export interface ProductPrice {
  id: string;
  price: number;
  currency: string;
}

export interface ProductUpdate {
  id: string;
  productId: string;
  providerId: string;
  productProviderId: string;
  stock: ProductStock;
  details: ProductDetails;
  price: ProductPrice;
  measuredAt: string;
}

// Defines a given Providers link to a Product
export interface ProductProvider {
  id: string;
  providerId: string;
  ean: string;
  sku: string;
  name: string;
  update: ProductUpdate;
}

export enum ProviderStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface Product {
  id: string;
  category?: string;
  providers: Array<ProductProvider>;
  status: ProviderStatus;
}

export interface Provider {
  id: string;
  name: string;
  status: ProviderStatus;
}

export interface ProviderProductDetails {
  provider: Provider;
  stock: PaginatedResponse<ProductStock>;
  prices: PaginatedResponse<ProductPrice>;
}

export enum Availability {
  AVAILABLE = 'Available',
  UNAVAILABLE = 'Unavailable',
}

export interface Category {
  id: string;
  name: string;
  children: Array<Category>;
  identifiers: Array<Identifier>;
}

export interface Identifier {
  id: string;
  name: string;
  value: string;
  office: string;
  type: string;
}
