import { useView } from 'hooks/useView';
import { User as UserModel } from 'models/Auth';
import { UserView } from 'views/UserView';
import { EmptyState } from 'components/EmptyState';
import { Paginator } from 'components/Paginator';
import { User } from 'components/users/User';

export const UsersTab = () => {
  const view = useView(UserView);
  return (
    <>
      {view.state.users?.data.length > 0 ? (
        <>
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="overflow-hidden border-b">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Username
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Roles
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {view.state.users.data.map(
                        (user: UserModel, index: number) => (
                          <User user={user} />
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Paginator
            total={view.state.users.total}
            page={view.state.users.page}
            numberOnPage={view.state.users.data.length}
            previousCallback={() => console.log('do nothing')}
            nextCallback={() => console.log('do nothing')}
          />
        </>
      ) : (
        <div className="my-10">
          <EmptyState
            object="User"
            colour="blue"
            onClick={() => console.log('do nothing')}
          />
        </div>
      )}
    </>
  );
};
