export interface JWTResponse {
  token: string;
  refreshToken: string;
  userId: string;
  expiresAt: number;
  createdAt: number;
  refreshTokenExpiresAt: number;
  refreshTokenCreatedAt: number;
}

export interface AuthTokens {
  access: string;
  refresh: string;
}

export interface Role {
  id: string;
  name: string;
  permissions: Array<string>;
}

export interface User {
  id?: string;
  email: string;
  username: string;
  roles?: Array<Role>;
  lastLoggedIn?: string;
  password?: string;
}

export const PERMISSIONS = {
  USER_UPDATE: 'Update a User',
  USER_ROLE_CREATE: 'Create a User Role',
  USER_ROLE_DELETE: 'Delete a User Role',
  USER_ROLE_READ: 'Read a User Role',
  USER_READ: 'Read a User',
  ALBANY_TASK_CREATE: 'Create an Albany Task',
  OFFICE_SPOT_TASK_UPDATE: 'Update an OfficeSpot Task',
  ALBANY_TASK_UPDATE: 'Update an Albany Task',
  USER_ROLE_UPDATE: 'Update a User Role',
  USER_DELETE: 'Delete a User',
  USER_CREATE: 'Create a User',
  OFFICE_SPOT_TASK_CREATE: 'Create an OfficeSpot Task',
  ALBANY_TASK_DELETE: 'Delete an Albany Task',
  ALBANY_TASK_READ: 'Read an Albany Task',
  OFFICE_SPOT_TASK_READ: 'Read an Office Spot Task',
  OFFICE_SPOT_TASK_DELETE: 'Delete an Office Spot Task',
  ALBANY_UK_TASK_READ: 'Read an Albany UK Task',
  ALBANY_UK_TASK_CREATE: 'Create an Albany UK Task',
  ALBANY_UK_TASK_DELETE: 'Delete an Albany UK Task',
  ALBANY_UK_TASK_UPDATE: 'Update an Albany UK Task',
};
