import { createSelector } from 'reselect';
import { singleton } from 'tsyringe';
import { PaginatedResponse } from 'models/Dashboard';
import { ShippingTask } from 'models/Shipping';
import { shippingActions, shippingSelector } from 'stores/shipping';
import { View } from 'views/View';
import Papa from 'papaparse';
import toast from 'react-hot-toast';
import { REQUIRED_COLUMNS } from 'constants/Reports';
import {
  BackOrderReleaseReportRow,
  BackOrderReportRow,
  GroupedUpdateOrder,
  ShippingIssueReportRow,
  ShippingUpdateType,
  UpdateOrderRow,
} from 'models/Reports';

@singleton()
export class ShippingUpdateView extends View {
  state!: {
    shippingUpdateTasks: PaginatedResponse<ShippingTask>;
    updateReport: Array<GroupedUpdateOrder>;
    updateReportParseError: boolean;
  };

  constructor() {
    super();
  }

  shouldDisplayEditor = () => {
    return this.state.updateReport;
  };

  setReportParseError = (updateReportParseError: boolean) => {
    this.store.dispatch(
      shippingActions.SetUpdateReportParseError({ updateReportParseError })
    );
  };

  submitUpdateFile = (updateFile: File, updateType: ShippingUpdateType) => {
    Papa.parse(updateFile, {
      complete: (updateFileContents) => {
        const parsedReport: Array<GroupedUpdateOrder> =
          this.parseUpdateFileContents(
            updateType,
            updateFileContents.data as Array<Array<string>>
          );
        if (!this.state.updateReportParseError) {
          this.store.dispatch(shippingActions.ClearUpdateReport());
          this.store.dispatch(
            shippingActions.SetUpdateReport({
              updateReport: parsedReport,
            })
          );
          this.store.dispatch(
            shippingActions.SetUpdateReportType({
              updateReportType: updateType,
            })
          );
        }
      },
    });
  };

  parseUpdateFileContents = (
    updateType: ShippingUpdateType,
    updateFileContents: Array<Array<string>>
  ): Array<GroupedUpdateOrder> => {
    const parsedReport: Array<GroupedUpdateOrder> = [];
    updateFileContents.map((row: Array<string>, index: number) => {
      const rowsToSkip =
        updateType === ShippingUpdateType.SHIPPING_ISSUE ? 1 : 0;
      if (!this.state.updateReportParseError && index > rowsToSkip) {
        if (row.length > 1) {
          if (row.length !== REQUIRED_COLUMNS[updateType]) {
            toast.error(
              `${updateType} Error: Found row (Number: ${
                index + 1
              }) with missing values. Carrier Reports require ${
                REQUIRED_COLUMNS[updateType]
              } values per row.`,
              {
                duration: 8000,
              }
            );
            this.setReportParseError(true);
          } else {
            let updateOrderRow: UpdateOrderRow = {} as UpdateOrderRow;
            if (updateType === ShippingUpdateType.BACK_ORDER) {
              updateOrderRow = this.generateBackOrderRow(row);
            } else if (updateType === ShippingUpdateType.BACK_ORDER_RELEASE) {
              updateOrderRow = this.generateBackOrderReleaseRow(row);
            } else if (updateType === ShippingUpdateType.SHIPPING_ISSUE) {
              updateOrderRow = this.generateShippingIssueRow(row);
            }
            const reportAlreadyContainsOrderGroup =
              this.findOrderGroupIndexInParsedReport(
                parsedReport,
                updateOrderRow
              );
            if (reportAlreadyContainsOrderGroup > -1) {
              parsedReport[
                reportAlreadyContainsOrderGroup
              ].updateOrderRows.push(updateOrderRow);
            } else {
              parsedReport.push({
                orderNumber: updateOrderRow.ourOrderNumber,
                updateOrderRows: [updateOrderRow],
              } as GroupedUpdateOrder);
            }
          }
        }
      }
    });
    return parsedReport;
  };

  findOrderGroupIndexInParsedReport = (
    parsedReport: Array<GroupedUpdateOrder>,
    updateOrderRow: UpdateOrderRow
  ): number => {
    return parsedReport.findIndex(
      (groupedUpdateOrder: GroupedUpdateOrder) =>
        groupedUpdateOrder.orderNumber === updateOrderRow.ourOrderNumber
    );
  };

  generateBackOrderRow = (row: Array<string>): BackOrderReportRow => {
    return {
      ourOrderNumber: row[0],
      yourOrderNumber: row[1],
      orderDate: row[2],
      productCode: row[3],
      description: row[4],
      quantityOrdered: row[5],
      quantityOustanding: row[6],
      requiredDate: row[7],
      freeStock: row[8],
      goodsInWaiting: row[9],
      date: row[10],
      nextPOQuantity: row[11],
      status: row[12],
    } as BackOrderReportRow;
  };

  generateBackOrderReleaseRow = (
    row: Array<string>
  ): BackOrderReleaseReportRow => {
    return {
      ourOrderNumber: row[0],
      yourOrderNumber: row[1],
      code: row[2],
      description: row[3],
      quantity: row[4],
      status: row[5],
      date: row[6],
      orderDate: row[7],
    } as BackOrderReleaseReportRow;
  };

  generateShippingIssueRow = (row: Array<string>): ShippingIssueReportRow => {
    return {
      ourOrderNumber: row[0],
      yourOrderNumber: row[1],
      parcelNumber: row[2],
      description: row[3],
      date: row[4],
      status: 'Shipping Issue',
    } as ShippingIssueReportRow;
  };

  getShippingUpdateTasks = (page?: number) => {
    this.store.dispatch(shippingActions.GetShippingUpdateTasks({ page }));
  };

  protected connected = () => {
    this.getShippingUpdateTasks();
  };

  protected stateMapper = createSelector(shippingSelector, (shipping) => {
    return {
      shippingUpdateTasks: shipping.shippingUpdateTasks,
      updateReport: shipping.updateReport,
      updateReportParseError: shipping.updateReportParseError,
    };
  });
}
