import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'models/UI';

interface Props {
  heading?: string;
  breadcrumbs?: Array<Breadcrumb>;
  buttons?: React.ReactNode;
}

export const DashboardHeading = ({ heading, breadcrumbs, buttons }: Props) => {
  return (
    <div>
      <div>
        <nav className="sm:hidden" aria-label="Back">
          <a
            href="#"
            className="flex items-center text-sm font-medium text-gray-400 hover:text-gray-200"
          >
            <ChevronLeftIcon
              className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
            Back
          </a>
        </nav>
        {breadcrumbs && (
          <nav className="hidden sm:flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
              {breadcrumbs.map((breadcrumb, index) => {
                return (
                  <li>
                    <div
                      className={`flex ${
                        index > 0 && index < breadcrumbs.length
                          ? 'items-center'
                          : ''
                      }`}
                    >
                      {index > 0 && index < breadcrumbs.length && (
                        <ChevronRightIcon
                          className="flex-shrink-0 h-5 w-5 text-gray-500"
                          aria-hidden="true"
                        />
                      )}
                      <Link
                        to={breadcrumb.link}
                        className={`${
                          index != 0 ? 'ml-4' : ''
                        } text-sm font-medium text-gray-500 hover:text-gray-700`}
                      >
                        {breadcrumb.title}
                      </Link>
                    </div>
                  </li>
                );
              })}
            </ol>
          </nav>
        )}
      </div>
      {heading && (
        <div className="mt-4 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {heading}
            </h2>
          </div>
          <div className="mr-2">{buttons}</div>
        </div>
      )}
    </div>
  );
};
