import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useView } from 'hooks/useView';
import { ShippingUpdateType } from 'models/Reports';
import { Size } from 'models/UI';
import { ShippingUpdateView } from 'views/ShippingUpdateView';
import { Button } from 'components/Button';
import { FileUpload } from 'components/FileUpload';

interface Props {
  updateFileType: ShippingUpdateType;
}

export const UploadUpdateFiles = ({ updateFileType }: Props) => {
  const view = useView(ShippingUpdateView);
  const [file, setFile] = useState<File | null>();

  useEffect(() => {
    setFile(null);
  }, [updateFileType]);

  return (
    <div>
      <div className="mb-4">
        <FileUpload
          subtitle={`${updateFileType} File`}
          onUpload={(file: File) => setFile(file)}
        />
      </div>
      <div className="flex">
        <div className="mr-2">
          <Button
            colour="blue"
            size={Size.Medium}
            onClick={() => {
              if (file) {
                view.submitUpdateFile(file, updateFileType);
              } else {
                toast.error(`Please upload a ${updateFileType} file.`);
              }
            }}
            text="Submit"
            icon="ArrowRightIcon"
          />
        </div>
      </div>
    </div>
  );
};
