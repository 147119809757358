import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { container } from 'tsyringe';
import { LoadingIcon } from 'components/LoadingIcon';
import { DashboardLayout } from 'layouts/DashboardLayout';
import { AuthService } from 'services/AuthService';

export const Logout = () => {
  const navigate = useNavigate();
  const authService = container.resolve(AuthService);

  useEffect(() => {
    authService.logout();
    navigate('/login');
  }, []);

  return (
    <DashboardLayout>
      <div className="h-screen w-full flex items-center justify-center -mt-24">
        <LoadingIcon colour="black" height="12" width="15" />
      </div>
    </DashboardLayout>
  );
};
