import { ProductPrice, ProductStock } from 'models/Product';
import { epochToDateTime } from './Date';

export const formatProductStockForChart = (
  productStocks: Array<ProductStock>
) => {
  return productStocks.map((productStock: ProductStock) => {
    return {
      // name: epochToDateTime(parseInt(productStock.measuredAt)),
      stock: productStock.stock,
      // amt: epochToDateTime(parseInt(productStock.measuredAt)),
    };
  });
};

export const formatProductPriceForChart = (
  productPrices: Array<ProductPrice>
) => {
  return productPrices.map((productPrice: ProductPrice) => {
    return {
      // name: epochToDateTime(parseInt(productPrice.measuredAt)),
      price: productPrice.price,
      // amt: epochToDateTime(parseInt(productPrice.measuredAt)),
    };
  });
};
