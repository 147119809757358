import { FieldArray, FormikErrors } from 'formik';
import { Order, OrderItem } from 'models/Reports';
import lodash from 'lodash';
import { Input } from 'components/Input';
import { Button } from 'components/Button';
import { Size } from 'models/UI';

interface Props {
  orderIndex: number;
  order: Order;
  errors: (FormikErrors<Order> | undefined)[];
}

// TODO: Not a page, move away
export const OrderItems = ({ orderIndex, order, errors }: Props) => {
  return (
    <FieldArray
      name={`${orderIndex}.items`}
      validateOnChange={true}
      render={(arrayHelpers) => (
        <>
          {order.items.length > 0 ? (
            <>
              {order.items.map(
                (orderItem: OrderItem, orderItemIndex: number) => {
                  return (
                    <div className="flex w-full mt-5">
                      <>
                        {Object.keys(orderItem).map((key: string) => {
                          return (
                            <div className="mr-5 flex-grow">
                              <Input
                                id={key}
                                type="text"
                                name={`${orderIndex}.items.${orderItemIndex}.${key}`}
                                label={lodash.startCase(key)}
                                errors={errors}
                              />
                            </div>
                          );
                        })}
                        <div className="my-auto">
                          <label
                            htmlFor={''}
                            className="block text-sm font-medium text-gray-700"
                          >
                            &nbsp;
                          </label>
                          <Button
                            colour="red"
                            size={Size.Medium}
                            onClick={() => {
                              arrayHelpers.remove(orderItemIndex);
                            }}
                            text="Delete"
                            icon="TrashIcon"
                          />
                        </div>
                      </>
                    </div>
                  );
                }
              )}
            </>
          ) : (
            <div className="mt-2 text-gray-700">No items in this order</div>
          )}
        </>
      )}
    />
  );
};
