import { useEffect } from 'react';
import { container } from 'tsyringe';
import { Alert } from 'components/Alert';
import { DashboardHeading } from 'components/DashboardHeading';
import { UserForm } from 'components/forms/UserForm';
import { useView } from 'hooks/useView';
import { DashboardLayout } from 'layouts/DashboardLayout';
import { AuthService } from 'services/AuthService';
import { UserView } from 'views/UserView';

export const Profile = () => {
  const view = useView(UserView);
  const authService = container.resolve(AuthService);

  useEffect(() => {
    view.getUser(authService.getUserId());
  }, [authService.getUserId()]);

  return (
    <DashboardLayout>
      <DashboardHeading
        heading="Profile"
        breadcrumbs={[
          { title: 'Home', link: '/dashboard' },
          { title: 'Profile', link: '/dashboard/profile' },
        ]}
      />
      <div className="mt-4 prose prose-sm text-gray-500">
        Update your profile information and change your password
      </div>
      <main className="max-w-lg pt-4 pb-12 lg:pb-16">
        <Alert
          colour="yellow"
          icon="ExclamationIcon"
          title="Warning"
          subtitle="Any changes you make to your profile will log you out. This will require you to log back in."
        />
        {view.state.user ? (
          <div className="space-y-6">
            <UserForm />
          </div>
        ) : (
          <>loadin g</>
        )}
      </main>
    </DashboardLayout>
  );
};
