import { createSelector } from 'reselect';
import { singleton } from 'tsyringe';
import { View } from 'views/View';
import Papa from 'papaparse';
import {
  CarrierReportRow,
  Order,
  OrderItem,
  PDIRReportRow,
  ReportError,
} from 'models/Reports';
import toast from 'react-hot-toast';
import { shippingActions, shippingSelector } from 'stores/shipping';
import { PaginatedResponse } from 'models/Dashboard';
import { ShippingTask } from 'models/Shipping';

@singleton()
export class ShippingTrackingView extends View {
  state!: {
    pdirReport: Array<PDIRReportRow>;
    carrierReport: Array<CarrierReportRow>;
    reportError: ReportError;
    trackingReport: Array<Order>;
    shippingTrackingTasks: PaginatedResponse<ShippingTask>;
  };

  constructor() {
    super();
  }

  protected connected = () => {
    this.getShippingTrackingTasks();
  };

  getShippingTrackingTasks = (page?: number) => {
    this.store.dispatch(shippingActions.GetShippingTrackingTasks({ page }));
  };

  submitReports = (pdirReport: File, carrierReport: File) => {
    Papa.parse(pdirReport, {
      complete: (pdirReportContents) => {
        Papa.parse(carrierReport, {
          complete: (carrierReportContents) => {
            this.store.dispatch(
              shippingActions.SetPDIRReport({
                pdirReport: this.parsePdirReport(
                  pdirReportContents.data as Array<Array<string>>
                ),
              })
            );
            this.store.dispatch(
              shippingActions.SetCarrierReport({
                carrierReport: this.parseCarrierReport(
                  carrierReportContents.data as Array<Array<string>>
                ),
              })
            );
            this.generateTrackingReport();
          },
        });
      },
    });
  };

  generateTrackingReport = () => {
    const trackingReport: Array<Order> = [];
    this.state.pdirReport.map((pdirReportRow: PDIRReportRow) => {
      const carrierReportRows: Array<CarrierReportRow> =
        this.state.carrierReport.filter(
          (carrierReportRow: CarrierReportRow) =>
            /* 
              Carrier Report rows can and do start with 0:  
              https://github.com/OfficeSpotDev/Shipping-Notifier-UI/blob/f87811b509043ec3999bdcc17ecda0c97e60de1c/src/services/ReportService.js#L25
            */
            carrierReportRow.orderNumber === pdirReportRow.orderNumber ||
            carrierReportRow.orderNumber === `0${pdirReportRow.orderNumber}`
        );
      const order: Order = this.generateOrder(pdirReportRow, carrierReportRows);
      console.log(order);
      if (order.items.length > 0) {
        trackingReport.push(order);
      }
    });
    this.store.dispatch(
      shippingActions.SetTrackingReport({
        trackingReport,
      })
    );
  };

  isValidTrackingReference = (carrierReportRow: CarrierReportRow) => {
    console.log('in is valid Tracking reference');
    console.log(carrierReportRow);
    // If it's a UPS tracking Link
    if (carrierReportRow.trackingURL.includes('wwwapps.ups.com')) {
      console.log('is UPS');
      console.log(carrierReportRow);
      // All UPS tracking Links must be 18 characters in length
      return carrierReportRow.trackingReference.length == 18;
    }
    return true;
  };

  generateOrder = (
    pdirReportRow: PDIRReportRow,
    carrierReportRows: Array<CarrierReportRow>
  ): Order => {
    /*
      While we match using the pdirReportRow.orderNumber, the "orderId" we want to use
      is actually the "e number" for Evo.
    */
    const order: Order = {
      orderId: pdirReportRow.eNumber,
      items: [],
    };
    carrierReportRows.map((carrierReportRow: CarrierReportRow) => {
      // Only include order Items with Unique Tracking Links, i.e. ones not already in order.items
      if (
        order.items.findIndex(
          (orderItem: OrderItem) =>
            orderItem.trackingLink == carrierReportRow.trackingURL
        ) === -1 &&
        this.isValidTrackingReference(carrierReportRow)
      ) {
        order.items.push({
          title: `Title for: ${carrierReportRow.trackingReference}`,
          label: `Tracking Link for: ${carrierReportRow.trackingReference}`,
          itemCount: 1,
          subtotal: 0,
          tax: 0,
          trackingCode: carrierReportRow.trackingReference,
          trackingLink: carrierReportRow.trackingURL,
          weight: carrierReportRow.calculatedWeight,
        } as OrderItem);
      }
    });
    return order;
  };

  parseCarrierReport = (
    rows: Array<Array<string>>
  ): Array<CarrierReportRow> => {
    const carrierReport: Array<CarrierReportRow> = [];
    rows.map((row: Array<string>, index: number) => {
      // Carrier Reports have a header row, skip it.
      if (index !== 0) {
        // It's a column number that isn't empty
        if (row.length > 1 && row.length < 18) {
          this.createReportError(rows, index);
          toast.error(
            `Carrier Report Error: Found row (Number: ${index}) with missing values. Carrier Reports require 15 values per row.`,
            {
              duration: 4000,
            }
          );
        } /* Has a full row */ else if (row.length === 18) {
          carrierReport.push(this.getCarrierReportRow(row));
        }
      }
    });
    return carrierReport;
  };

  getCarrierReportRow = (row: Array<string>) => {
    return {
      dateCreated: row[0],
      accountNumber: row[1],
      orderNumber: row[2],
      customerName: row[3],
      customerOrderNumber: row[4],
      customerSalesOrderNumber: row[5],
      postcode: row[6],
      trackingReference: row[7],
      trackingURL: row[8],
      productCode: row[9],
      productDescription: row[10],
      quantity: row[11],
      despatchCourier: row[12],
      calculatedWeight: parseFloat(row[13]),
      carrierID: row[14],
      deliveryStatus: row[15],
      buyersLineReference: row[16],
      customerTrackingReference: row[17],
    } as CarrierReportRow;
  };

  createReportError = (rows: Array<Array<string>>, index: number) => {
    let reportError: ReportError = {
      type: 'Missing Columns',
      currentRow: {
        number: index + 1,
        content: rows[index],
      },
    };
    if (index !== -1) {
      reportError = {
        ...reportError,
        previousRow: {
          number: index,
          content: rows[index - 1],
        },
      };
    }
    if (rows.length > index + 1) {
      reportError = {
        ...reportError,
        nextRow: {
          number: index + 2,
          content: rows[index + 1],
        },
      };
    }

    this.store.dispatch(
      shippingActions.SetReportError({
        reportError: reportError,
      })
    );
  };

  parsePdirReport = (rows: Array<Array<string>>): Array<PDIRReportRow> => {
    const pdirReport: Array<PDIRReportRow> = [];
    rows.map((row: Array<string>, index: number) => {
      if (row.length > 1 && row.length < 4) {
        this.createReportError(rows, index);
        toast.error(
          `PDIR Report Error: Found row (Number: ${
            index + 1
          }) with missing values. PDIR Reports require 4 values per row.`,
          {
            duration: 4000,
          }
        );
      } /* Has a full row */ else if (row.length === 4) {
        pdirReport.push(this.getPDIRReportRow(row));
      }
    });
    return pdirReport;
  };

  getPDIRReportRow = (row: Array<string>): PDIRReportRow => {
    return {
      pdirNumber: row[0],
      orderNumber: row[1],
      sordNumber: row[2],
      eNumber: this.parseENumber(row[3]),
    } as PDIRReportRow;
  };

  parseENumber = (eNumber: string): string => {
    const regex = /e_(.*?)\//;
    const result = regex.exec(eNumber);
    if (result) {
      return result[1];
    }
    return '';
  };

  protected stateMapper = createSelector(shippingSelector, (shipping) => {
    return {
      pdirReport: shipping.pdirReport,
      carrierReport: shipping.carrierReport,
      trackingReport: shipping.trackingReport,
      reportError: shipping.reportError,
      shippingTrackingTasks: shipping.shippingTrackingTasks,
    };
  });
}
