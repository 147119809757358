import { singleton } from 'tsyringe';
import { View } from 'views/View';
import { createSelector } from 'reselect';
import { PaginatedResponse, SearchResponse } from 'models/Dashboard';
import {
  Category,
  Identifier,
  Product,
  ProductProvider,
  Provider,
  ProviderProductDetails,
} from 'models/Product';
import {
  dashboard,
  dashboardActions,
  dashboardSelector,
} from 'stores/dashboard';
import * as Yup from 'yup';

@singleton()
export class ProductView extends View {
  state!: {
    product: Product;
    products: PaginatedResponse<Product>;
    categories: PaginatedResponse<Category>;
    searchQuery: string;
    searchedProducts: SearchResponse<Product>;
    productsLoading: boolean;
    providers: Array<Provider>;
    //providerProductDetails: Array<ProviderProductDetails>;
    providerProductUpdates: any;
  };

  constructor() {
    super();
  }

  get identifierValidationSchema(): Yup.AnySchema {
    return Yup.object().shape({
      value: Yup.string().required('Required'),
      name: Yup.string().required('Required'),
    });
  }

  deleteCategoryIdentifier = (categoryId: string, identifierId: string) => {
    this.store.dispatch(
      dashboardActions.DeleteCategoryIdentifier({
        categoryId,
        identifierId,
      })
    );
  };

  updateCategoryIdentifier = (
    categoryId: string,
    identifierId: string,
    identifier: Identifier
  ) => {
    this.store.dispatch(
      dashboardActions.UpdateCategoryIdentifier({
        categoryId,
        identifierId,
        identifier,
      })
    );
  };

  createCategoryIdentifier = (categoryId: string, identifier: Identifier) => {
    this.store.dispatch(
      dashboardActions.CreateCategoryIdentifier({
        categoryId,
        identifier,
      })
    );
  };

  setProductIsLoading = (productsLoading: boolean) => {
    this.store.dispatch(
      dashboardActions.SetProductsLoading({ productsLoading })
    );
  };

  getProviders = () => {
    console.log('getting providers');
    this.store.dispatch(dashboardActions.GetProviders());
  };

  clearProviders = () => {
    this.store.dispatch(dashboardActions.ClearProviders());
  };

  clearProviderUpdates = () => {
    this.store.dispatch(dashboardActions.ClearProviderUpdates());
  };

  getProducts = (page?: number) => {
    this.store.dispatch(
      dashboardActions.SetProductsLoading({ productsLoading: true })
    );
    this.store.dispatch(dashboardActions.GetProducts({ page }));
  };

  getCategories = (page?: number) => {
    this.store.dispatch(dashboardActions.GetCategories({ page }));
  };

  searchProducts = (query: string) => {
    this.setProductIsLoading(true);
    this.store.dispatch(dashboardActions.SearchProducts({ query }));
  };

  getProduct = (id: string) => {
    this.store.dispatch(dashboardActions.GetProduct({ id }));
  };

  clearProduct = () => {
    this.store.dispatch(dashboardActions.ClearProduct());
  };

  clearProducts = () => {
    this.store.dispatch(dashboardActions.ClearProducts());
  };

  getProviderProductUpdates = (providerId: string) => {
    this.store.dispatch(
      dashboardActions.GetProviderProductUpdates({
        productId: this.state.product.id,
        providerId,
      })
    );
  };

  protected stateMapper = createSelector(dashboardSelector, (dashboard) => {
    return {
      product: dashboard.product,
      products: dashboard.products,
      categories: dashboard.categories,
      searchQuery: dashboard.searchQuery,
      searchedProducts: dashboard.searchedProducts,
      productsLoading: dashboard.productsLoading,
      providers: dashboard.providers,
      //providerProductDetails: dashboard.providerProductDetails,
      providerProductUpdates: dashboard.providerProductUpdates,
    };
  });
}
