import { createSelector } from 'reselect';
import { singleton } from 'tsyringe';
import { User } from 'models/Auth';
import { authActions, authSelector } from 'stores/auth';
import { View } from 'views/View';
import * as Yup from 'yup';
import { NavigateFunction } from 'react-router';

@singleton()
export class AuthView extends View {
  state!: {
    user: User;
    isLoggingIn?: boolean;
  };

  constructor() {
    super();
  }

  validationSchema = () => {
    return Yup.object().shape({
      username: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
    });
  };

  initialValues = () => {
    return {
      username: '',
      password: '',
    };
  };

  login = (username: string, password: string, navigate: NavigateFunction) => {
    this.store.dispatch(authActions.SetLoggingIn({ isLoggingIn: true }));
    this.store.dispatch(authActions.Login({ username, password, navigate }));
  };

  protected stateMapper = createSelector(authSelector, (auth) => {
    return {
      user: auth.user,
      isLoggingIn: auth.isLoggingIn,
    };
  });
}
