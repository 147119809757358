import { LockClosedIcon } from '@heroicons/react/solid';
import { Formik, FormikValues } from 'formik';
import { useNavigate } from 'react-router';
import { Input } from 'components/Input';
import { LoadingIcon } from 'components/LoadingIcon';
import { useView } from 'hooks/useView';
import { AuthView } from 'views/LoginView';

export const Login = () => {
  const view = useView(AuthView);
  const navigate = useNavigate();

  return (
    <>
      <div className="-mt-4 h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <LockClosedIcon className="h-20 mx-auto text-blue-500" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Albany Supplies
            </h2>
            <p className="mt-2 text-center text-sm text-blue-600">
              Login to your Account
            </p>
          </div>
          <Formik
            initialValues={view.initialValues}
            validationSchema={view.validationSchema}
            onSubmit={(values: FormikValues) => {
              view.login(values.username, values.password, navigate);
            }}
            enableReinitialize
          >
            {({ errors, handleSubmit, setFieldValue, values }) => (
              <form
                className="mt-8 space-y-6"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              >
                <div className="-mb-3">
                  <Input
                    id="username"
                    type="text"
                    name="username"
                    placeholder="Username"
                    errors={errors}
                    disabled={view.state.isLoggingIn}
                  />
                </div>
                <div className="-mt-4 -pt-2">
                  <Input
                    id="password"
                    type="password"
                    name="password"
                    placeholder="Password"
                    errors={errors}
                    disabled={view.state.isLoggingIn}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    handleSubmit();
                  }}
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    {view.state.isLoggingIn ? (
                      <div className="px-1">
                        <LoadingIcon />
                      </div>
                    ) : (
                      <LockClosedIcon
                        className="h-5 w-5 text-blue-500 group-hover:text-blue-400"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                  Login
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};
