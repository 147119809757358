import { PlusIcon, SearchIcon } from '@heroicons/react/solid';

interface Props {
  colour: string;
  object: string;
  onClick?: () => void;
}

export const EmptyState = ({ colour, object, onClick }: Props) => {
  return (
    <div className="text-center">
      <SearchIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-medium text-gray-900">No {object}s</h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new {object}.
      </p>
      {onClick && (
        <div className="mt-6">
          <button
            type="button"
            onClick={() => onClick()}
            className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-${colour}-600 hover:bg-${colour}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${colour}-500`}
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Create {object}
          </button>
        </div>
      )}
    </div>
  );
};
