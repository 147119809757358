import { useState } from 'react';
import { container } from 'tsyringe';
import { DashboardHeading } from 'components/DashboardHeading';
import { UpdateForm } from 'components/forms/UpdateForm';
import { Paginator } from 'components/Paginator';
import { UploadUpdateFiles } from 'components/reports/UploadUpdateFiles';
import { TasksTable } from 'components/tasks/TasksTable';
import { useView } from 'hooks/useView';
import { DashboardLayout } from 'layouts/DashboardLayout';
import { ShippingUpdateType } from 'models/Reports';
import { OfficeService } from 'services/OfficeService';
import { classNames } from 'utils/styles';
import { ShippingUpdateView } from 'views/ShippingUpdateView';

const tabs: Array<{ name: string; type: ShippingUpdateType }> = [
  { name: 'Back Order', type: ShippingUpdateType.BACK_ORDER },
  {
    name: 'Back Order Released',
    type: ShippingUpdateType.BACK_ORDER_RELEASE,
  },
  {
    name: 'Shipping Issue',
    type: ShippingUpdateType.SHIPPING_ISSUE,
  },
];

export const ShippingUpdates = () => {
  const view = useView(ShippingUpdateView);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const officeService = container.resolve(OfficeService);

  const requiredPermission = () => {
    return `${officeService.getOfficePermissionName()}_TASK_READ`;
  };

  return (
    <DashboardLayout permission={requiredPermission()}>
      <DashboardHeading
        heading="Shippings Updates"
        breadcrumbs={[
          { title: 'Home', link: '/dashboard' },
          { title: 'Shipping Updates', link: '/dashboard/shipping/updates' },
        ]}
      />
      {view.shouldDisplayEditor() ? (
        <UpdateForm updateType={tabs[currentTab].type} />
      ) : (
        <>
          <div className="mt-4 prose prose-sm text-gray-500">
            Use a variety of reports to generate Shipping Updates regarding
            orders.
          </div>
          <div className="-mt-2">
            <main className="flex-1">
              <div className="relative mx-auto md:px-8 xl:px-0">
                <div className="pb-16">
                  <div className="px-4 sm:px-6 md:px-0">
                    <div className="py-6">
                      {/* Tabs */}
                      <div className="lg:hidden">
                        <label htmlFor="selected-tab" className="sr-only">
                          Select a tab
                        </label>
                        <select
                          id="selected-tab"
                          name="selected-tab"
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm rounded-md"
                          defaultValue={'Users'}
                        >
                          {tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="hidden lg:block">
                        <div className="border-b border-gray-200">
                          <nav className="-mb-px flex space-x-8">
                            {tabs.map((tab, index) => (
                              <span
                                key={tab.name}
                                onClick={() => setCurrentTab(index)}
                                className={classNames(
                                  index === currentTab
                                    ? 'border-blue-500 text-blue-600'
                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                                )}
                              >
                                {tab.name}
                              </span>
                            ))}
                          </nav>
                        </div>
                      </div>
                      <div className="">
                        <UploadUpdateFiles
                          updateFileType={tabs[currentTab].type}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <div className="-mt-10 mb-10">
            <DashboardHeading heading="Tasks" />
            {view.state.shippingUpdateTasks?.data.length > 0 ? (
              <>
                <div className="flex flex-col mt-5">
                  <div className="-my-2 sm:-mx-6 lg:-mx-8">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                      <div className="border-b">
                        <TasksTable
                          tasks={view.state.shippingUpdateTasks.data}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <Paginator
                  total={view.state.shippingUpdateTasks.total}
                  page={view.state.shippingUpdateTasks.page}
                  numberOnPage={view.state.shippingUpdateTasks.data.length}
                  previousCallback={() =>
                    view.getShippingUpdateTasks(
                      view.state.shippingUpdateTasks.page - 1
                    )
                  }
                  nextCallback={() =>
                    view.getShippingUpdateTasks(
                      view.state.shippingUpdateTasks.page + 1
                    )
                  }
                />
              </>
            ) : (
              <div className="my-10">
                <div className="-mt-6 prose prose-sm text-gray-500">
                  There are no Shipping Update Tasks yet. Any new tasks will
                  appear here.
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </DashboardLayout>
  );
};
