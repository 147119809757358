import { Field } from 'formik';

interface Props {
  name: string;
  value: string;
  label: string;
  subLabel?: string;
}

export const Checkbox = ({ name, value, label, subLabel }: Props) => {
  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <Field
          type="checkbox"
          name={name}
          value={value}
          className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor="comments" className="font-medium text-gray-700">
          {label}
        </label>
        {subLabel && (
          <span id="comments-description" className="text-gray-500">
            {subLabel}
          </span>
        )}
      </div>
    </div>
  );
};
