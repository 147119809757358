import { PhotographIcon, QrcodeIcon, SearchIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { container } from 'tsyringe';
import { DashboardHeading } from 'components/DashboardHeading';
import { useView } from 'hooks/useView';
import { DashboardLayout } from 'layouts/DashboardLayout';
import {
  Category as CategoryModel,
  Product as ProductModel,
} from 'models/Product';
import { OfficeService } from 'services/OfficeService';
import { ProductView } from 'views/ProductView';
import { LoadingIcon } from 'components/LoadingIcon';
import { Paginator } from 'components/Paginator';
import { Button } from 'components/Button';
import { Size } from 'models/UI';
import { Search } from 'components/product/Search';
import { Product } from 'components/product/Product';
import { Category } from './Category';

export const Categories = () => {
  const view = useView(ProductView);
  const [initialProductsRetrieved, setInitialProductsRetrieved] =
    useState(false);
  const officeService = container.resolve(OfficeService);
  const [searchOpen, setSearchOpen] = useState(false);

  // useEffect(() => {
  //   view.clearProducts();
  // }, []);

  useEffect(() => {
    // view.getProviders();
    view.getCategories();
  }, []);

  const requiredPermission = () => {
    return `${officeService.getOfficePermissionName()}_PRODUCT_READ`;
  };

  return (
    <DashboardLayout permission={requiredPermission()}>
      <DashboardHeading
        heading="Categories"
        breadcrumbs={[
          { title: 'Home', link: '/dashboard' },
          { title: 'Products', link: '/dashboard/products' },
          { title: 'Categories', link: '/dashboard/products/categories' },
        ]}
      />

      {!view.state.categories ? (
        <div className="h-screen w-full flex items-center justify-center -mt-32">
          <LoadingIcon colour="black" height="12" width="15" />
        </div>
      ) : (
        <>
          {/* <div className="mt-4">
            <Paginator
              page={view.state.products.page}
              total={view.state.products.total}
              numberOnPage={view.state.products.size}
              previousCallback={() => {
                view.getProducts(view.state.products.page - 1);
              }}
              nextCallback={() =>
                view.getProducts(view.state.products.page + 1)
              }
              hasBorder={false}
            />
          </div> */}
          {view.state.categories?.data.length > 0 ? (
            <div className="mt-6">
              <div className="flex flex-col">
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden border-b">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              Identifiers
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {view.state.categories.data.map(
                            (category: CategoryModel, index: number) => (
                              <Category category={category} />
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <Paginator
                total={view.state.categories.total}
                page={view.state.categories.page}
                numberOnPage={view.state.categories.data.length}
                previousCallback={() => console.log('do nothing')}
                nextCallback={() => console.log('do nothing')}
              />
            </div>
          ) : (
            <div className="mt-2">
              <>No Categories</>
            </div>
          )}
        </>
      )}
    </DashboardLayout>
  );
};
