import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { auth, authSaga, AuthState } from 'stores/auth';
import { dashboard, dashboardSaga, DashboardState } from 'stores/dashboard';
import { shipping, shippingSaga, ShippingState } from 'stores/shipping';

export const STORE_TOKEN = 'store';

export interface State {
  dashboard: DashboardState;
  shipping: ShippingState;
  auth: AuthState;
}

export const initStore = () => {
  const rootReducer = combineReducers<State>({
    dashboard: dashboard.reducer,
    shipping: shipping.reducer,
    auth: auth.reducer,
  });

  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
    devTools: true,
  });

  sagaMiddleware.run(dashboardSaga);
  sagaMiddleware.run(shippingSaga);
  sagaMiddleware.run(authSaga);

  return store;
};
