import { Dialog } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/solid';
import { ModalSubmitButton } from 'components/ModalSubmitButton';

interface Props {
  title: string;
  subtitle: string;
  performDelete: () => void;
}

export const DeleteConfirmation = ({
  title,
  subtitle,
  performDelete,
}: Props) => {
  return (
    <div>
      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
        <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title
          as="h3"
          className="text-lg leading-6 font-medium text-gray-900"
        >
          {title}
        </Dialog.Title>
        <div className="mt-2">
          <p className="text-sm text-gray-500">{subtitle}</p>
        </div>
        <ModalSubmitButton
          onSubmit={() => performDelete()}
          colour="red"
          text="Delete"
        />
      </div>
    </div>
  );
};
