import { useEffect, useState } from 'react';
import { container } from 'tsyringe';
import { View } from 'views/View';

export const useView = <T extends typeof View>(token: T): InstanceType<T> => {
  const [, triggerUpdate] = useState({});
  const [vm] = useState(() => {
    const vm = container.resolve(token as any);
    if (!(vm instanceof View)) {
      throw new Error('View must extend the View base class');
    }
    vm._onStateChange();
    return vm;
  });

  useEffect(() => {
    const disconnect = vm._connect(() => {
      triggerUpdate({});
    });
    triggerUpdate({});
    return disconnect;
  }, [vm]);

  return vm as any;
};
