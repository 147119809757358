import { Menu, Transition } from '@headlessui/react';
import {
  ChevronDownIcon,
  MenuAlt1Icon,
  SearchIcon,
} from '@heroicons/react/solid';
import { UserIcon } from '@heroicons/react/outline';
import { Fragment, useState } from 'react';
import { DashboardNav } from 'components/navs/DashboardNav';
import { classNames } from 'utils/styles';
import { Link } from 'react-router-dom';
import { useView } from 'hooks/useView';
import { AuthView } from 'views/LoginView';
import { ProtectedLayout } from 'layouts/ProtectedLayout';
import { ProfileNav } from 'components/navs/ProfileNav';

interface Props {
  children: any;
  permission?: string;
}

export const DashboardLayout = ({ children, permission }: Props) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const view = useView(AuthView);

  return (
    <ProtectedLayout permission={permission}>
      <div className="h-screen flex">
        <DashboardNav
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        {/* Main column */}
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          {/* Search header */}
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
            {/* Page title & actions */}
            <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
              <div className="flex-1 min-w-0"></div>
              <div className="mt-4 flex sm:mt-0 sm:ml-4">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                      <UserIcon className="h-6 h-4" />
                      <span className="hidden ml-2 text-gray-700 text-sm font-medium lg:block">
                        <span className="sr-only">Open user menu for </span>
                        {view.state.user.username}
                      </span>
                      <ChevronDownIcon
                        className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        <p className="py-3 px-3.5 truncate">
                          <span className="block mb-0.5 text-xs text-gray-500">
                            Logged in as
                          </span>
                          <span className="font-semibold">
                            {view.state.user.email}
                          </span>
                        </p>
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/dashboard/profile"
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Your Profile
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/logout"
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Logout
                          </Link>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="px-8 pt-6">{children}</div>
          </main>
        </div>
      </div>
    </ProtectedLayout>
  );
};
