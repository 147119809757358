export const REQUIRED_COLUMNS = {
  'Back Order': 13,
  'Back Order Released': 8,
  'Shipping Issue': 5,
};

export const BACK_ORDER_REPORT_NUMBER_OF_COLUMNS = 13;
export const BACK_ORDER_RELEASE_REPORT_NUMBER_OF_COLUMNS = 8;

export const REPORT_DATE_FORMAT = 'DD/MM/YYYY';
