import { put, fork, takeEvery, select } from '@redux-saga/core/effects';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { container } from 'tsyringe';
import { Role, User } from 'models/Auth';
import { PaginatedResponse, SearchResponse } from 'models/Dashboard';
import {
  Category,
  Identifier,
  Product,
  ProductPrice,
  ProductStock,
  ProductUpdate,
  Provider,
  ProviderProductDetails,
} from 'models/Product';
import { Backend } from 'services/Backend';
import { OfficeService } from 'services/OfficeService';
import { State } from './store';
import { USER_ID_KEY } from 'constants/Auth';
import { AxiosError } from 'axios';

export interface DashboardState {
  activeOffice: string;
  products?: PaginatedResponse<Product>;
  categories?: PaginatedResponse<Category>;
  searchQuery: string;
  searchedProducts?: SearchResponse<Product>;
  product?: Product;
  productsLoading: boolean;
  providers?: Array<Provider>;
  //providerProductDetails?: Array<ProviderProductDetails>;
  providerProductUpdates?: { [providerId: string]: Array<ProductUpdate> };
  user?: User;
  role?: Role;
  users?: PaginatedResponse<User>;
  roles?: PaginatedResponse<Role>;
  userActionModalOpen: boolean;
  userRoleActionModalOpen: boolean;
  roleActionModalOpen: boolean;
}

const initialState = {
  products: undefined,
  productsLoading: true,
  userActionModalOpen: false,
  userRoleActionModalOpen: false,
  roleActionModalOpen: false,
} as DashboardState;

export const dashboard = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    GetProviders(state: DashboardState) {},

    ClearProviders(state: DashboardState) {
      state.providers = undefined;
    },

    ClearProviderUpdates(state: DashboardState) {
      state.providerProductUpdates = undefined;
    },

    SetProviders(
      state: DashboardState,
      action: PayloadAction<{ providers: Array<Provider> }>
    ) {
      state.providers = action.payload.providers;
    },

    SetProductsLoading(
      state: DashboardState,
      action: PayloadAction<{ productsLoading: boolean }>
    ) {
      state.productsLoading = action.payload.productsLoading;
    },

    GetProducts(
      state: DashboardState,
      action: PayloadAction<{ page?: number }>
    ) {},

    GetCategories(
      state: DashboardState,
      action: PayloadAction<{ page?: number }>
    ) {},

    SearchProducts(
      state: DashboardState,
      action: PayloadAction<{ query: string }>
    ) {},

    SetSearchQuery(
      state: DashboardState,
      action: PayloadAction<{ query: string }>
    ) {
      state.searchQuery = action.payload.query;
    },

    GetProduct(state: DashboardState, action: PayloadAction<{ id: string }>) {},

    GetProviderProductUpdates(
      state: DashboardState,
      action: PayloadAction<{ productId: string; providerId: string }>
    ) {},

    // SetProviderProductDetails(
    //   state: DashboardState,
    //   action: PayloadAction<{
    //     provider: Provider;
    //     prices: PaginatedResponse<ProductPrice>;
    //     stock: PaginatedResponse<ProductStock>;
    //   }>
    // ) {
    //   const productProviderDetails: ProviderProductDetails = {
    //     provider: action.payload.provider,
    //     stock: action.payload.stock,
    //     prices: action.payload.prices,
    //   };
    //   if (state.providerProductDetails != undefined) {
    //     state.providerProductDetails = [
    //       ...state.providerProductDetails,
    //       productProviderDetails,
    //     ];
    //   } else {
    //     state.providerProductDetails = [productProviderDetails];
    //   }
    // },

    SetProviderProductUpdates(
      state: DashboardState,
      action: PayloadAction<{
        providerId: string;
        updates: PaginatedResponse<ProductUpdate>;
      }>
    ) {
      state.providerProductUpdates = {
        [action.payload.providerId]: action.payload.updates.data,
      };
    },

    SetProducts(
      state: DashboardState,
      action: PayloadAction<{ products: PaginatedResponse<Product> }>
    ) {
      state.products = action.payload.products;
    },

    SetCategories(
      state: DashboardState,
      action: PayloadAction<{ categories: PaginatedResponse<Category> }>
    ) {
      state.categories = action.payload.categories;
    },

    SetSearchedProducts(
      state: DashboardState,
      action: PayloadAction<{ products: SearchResponse<Product> }>
    ) {
      state.searchedProducts = action.payload.products;
    },

    SetProduct(
      state: DashboardState,
      action: PayloadAction<{ product: Product }>
    ) {
      state.product = action.payload.product;
    },

    ClearProduct(state: DashboardState, action: PayloadAction) {
      state.product = undefined;
    },

    ClearProducts(state: DashboardState, action: PayloadAction) {
      state.products = undefined;
    },

    ClearSearchedProducts(state: DashboardState, action: PayloadAction) {
      state.searchedProducts = undefined;
    },

    GetUsers(
      state: DashboardState,
      action: PayloadAction<{ page?: number }>
    ) {},

    GetUser(state: DashboardState, action: PayloadAction<{ id: string }>) {},

    ClearUser(state: DashboardState) {
      state.user = undefined;
    },

    SetUser(state: DashboardState, action: PayloadAction<{ user: User }>) {
      state.user = action.payload.user;
    },

    SetUsers(
      state: DashboardState,
      action: PayloadAction<{ users: PaginatedResponse<User> }>
    ) {
      state.users = action.payload.users;
    },

    GetRoles(
      state: DashboardState,
      action: PayloadAction<{ page?: number }>
    ) {},

    GetRole(state: DashboardState, action: PayloadAction<{ id: string }>) {},

    ClearRole(state: DashboardState) {
      state.role = undefined;
    },

    SetRole(state: DashboardState, action: PayloadAction<{ role: Role }>) {
      state.role = action.payload.role;
    },

    SetRoles(
      state: DashboardState,
      action: PayloadAction<{ roles: PaginatedResponse<Role> }>
    ) {
      state.roles = action.payload.roles;
    },

    CreateRole(state: DashboardState, action: PayloadAction<{ role: Role }>) {},

    EditRole(
      state: DashboardState,
      action: PayloadAction<{ id: string; role: Role }>
    ) {},

    EditUser(
      state: DashboardState,
      action: PayloadAction<{ id: string; user: User }>
    ) {},

    AddRolesToUser(
      state: DashboardState,
      action: PayloadAction<{ userId: string; roles: Array<string> }>
    ) {},

    SetUserPassword(
      state: DashboardState,
      action: PayloadAction<{ userId: string; password: string }>
    ) {},

    DeleteRole(state: DashboardState, action: PayloadAction<{ id: string }>) {},

    CreateUser(state: DashboardState, action: PayloadAction<{ user: User }>) {},

    DeleteUser(state: DashboardState, action: PayloadAction<{ id: string }>) {},

    SetUserActionModalOpen(
      state: DashboardState,
      action: PayloadAction<{ userActionModalOpen: boolean }>
    ) {
      state.userActionModalOpen = action.payload.userActionModalOpen;
    },

    SetUserRoleActionModalOpen(
      state: DashboardState,
      action: PayloadAction<{ userRoleActionModalOpen: boolean }>
    ) {
      state.userRoleActionModalOpen = action.payload.userRoleActionModalOpen;
    },

    SetRoleActionModalOpen(
      state: DashboardState,
      action: PayloadAction<{ roleActionModalOpen: boolean }>
    ) {
      state.roleActionModalOpen = action.payload.roleActionModalOpen;
    },

    AddPermissionsToRole(
      state: DashboardState,
      action: PayloadAction<{ roleId: string; permissions: Array<string> }>
    ) {},

    UpdateCategoryIdentifier(
      state: DashboardState,
      action: PayloadAction<{
        categoryId: string;
        identifierId: string;
        identifier: Identifier;
      }>
    ) {},

    CreateCategoryIdentifier(
      state: DashboardState,
      action: PayloadAction<{
        categoryId: string;
        identifier: Identifier;
      }>
    ) {},

    DeleteCategoryIdentifier(
      state: DashboardState,
      action: PayloadAction<{
        categoryId: string;
        identifierId: string;
      }>
    ) {},
  },
});

export const dashboardActions = {
  ...dashboard.actions,
};

export const dashboardSelector = (state: any) =>
  state[dashboard.name] as DashboardState;

function* dashboardSideEffects() {
  const backend = container.resolve(Backend);
  const officeService = container.resolve(OfficeService);
  const storage: Storage = window?.localStorage;

  yield takeEvery(dashboardActions.GetProviders, function* (action) {
    try {
      const response: Array<Provider> = yield backend.getProviders(
        officeService.getOfficeAPIName()
      );
      yield put(dashboardActions.SetProviders({ providers: response }));
    } catch {}
  });

  yield takeEvery(
    dashboardActions.GetProviderProductUpdates,
    function* (action) {
      // Probably need to handle pagination in the future but sure look, 10 is fine for now
      const updates: PaginatedResponse<ProductUpdate> =
        yield backend.getProviderProductUpdates(
          officeService.getOfficeAPIName(),
          action.payload.providerId,
          action.payload.productId
        );
      yield put(
        dashboardActions.SetProviderProductUpdates({
          providerId: action.payload.providerId,
          updates,
        })
      );
    }
  );

  yield takeEvery(dashboardActions.GetProducts, function* (action) {
    try {
      const response: PaginatedResponse<Product> = yield backend.getProducts(
        officeService.getOfficeAPIName(),
        action.payload.page
      );
      yield put(dashboardActions.SetProducts({ products: response }));
    } catch {}
  });

  yield takeEvery(dashboardActions.GetCategories, function* (action) {
    const response: PaginatedResponse<Category> = yield backend.getCategories(
      officeService.getOfficeAPIName(),
      action.payload.page
    );
    yield put(dashboardActions.SetCategories({ categories: response }));
  });

  yield takeEvery(dashboardActions.SearchProducts, function* (action) {
    try {
      yield put(dashboardActions.ClearSearchedProducts());
      const response: SearchResponse<Product> = yield backend.searchProducts(
        officeService.getOfficeAPIName(),
        action.payload.query
      );
      yield put(dashboardActions.SetSearchedProducts({ products: response }));
      yield put(
        dashboardActions.SetSearchQuery({ query: action.payload.query })
      );
    } catch {}
  });

  yield takeEvery(dashboardActions.SetProducts, function* (action) {
    yield put(dashboardActions.SetProductsLoading({ productsLoading: false }));
  });

  yield takeEvery(dashboardActions.SetSearchedProducts, function* (action) {
    yield put(dashboardActions.SetProductsLoading({ productsLoading: false }));
  });

  yield takeEvery(dashboardActions.GetProduct, function* (action) {
    try {
      const response: Product = yield backend.getProduct(
        officeService.getOfficeAPIName(),
        action.payload.id
      );
      yield put(dashboardActions.SetProduct({ product: response }));
    } catch {}
  });

  yield takeEvery(dashboardActions.GetUsers, function* (action) {
    try {
      const response: PaginatedResponse<User> = yield backend.getUsers(
        action.payload.page
      );
      yield put(dashboardActions.SetUsers({ users: response }));
    } catch {}
  });

  yield takeEvery(dashboardActions.GetRoles, function* (action) {
    try {
      const response: PaginatedResponse<Role> = yield backend.getRoles(
        action.payload.page
      );
      yield put(dashboardActions.SetRoles({ roles: response }));
    } catch {}
  });

  yield takeEvery(dashboardActions.GetUser, function* (action) {
    try {
      const response: User = yield backend.getUser(action.payload.id);
      yield put(dashboardActions.SetUser({ user: response }));
    } catch {}
  });

  yield takeEvery(dashboardActions.GetRole, function* (action) {
    try {
      const response: Role = yield backend.getRole(action.payload.id);
      yield put(dashboardActions.SetRole({ role: response }));
    } catch {}
  });

  yield takeEvery(dashboardActions.CreateRole, function* (action) {
    try {
      const response: Role = yield backend.createRole(action.payload.role);
      yield put(
        dashboardActions.AddPermissionsToRole({
          roleId: response.id,
          permissions: action.payload.role.permissions,
        })
      );
      yield put(dashboardActions.GetRoles({}));
      toast.success('Successfully created Role');
      yield put(
        dashboardActions.SetRoleActionModalOpen({ roleActionModalOpen: false })
      );
    } catch {}
  });

  yield takeEvery(dashboardActions.AddPermissionsToRole, function* (action) {
    try {
      const response: Role = yield backend.addPermissionsToRole(
        action.payload.roleId,
        action.payload.permissions
      );
    } catch {}
  });

  yield takeEvery(dashboardActions.EditRole, function* (action) {
    try {
      const response: Role = yield backend.editRole(
        action.payload.id,
        action.payload.role
      );
      yield put(
        dashboardActions.AddPermissionsToRole({
          roleId: response.id,
          permissions: action.payload.role.permissions,
        })
      );
      yield put(dashboardActions.GetRoles({}));
      toast.success('Successfully edited Role');
      yield put(
        dashboardActions.SetRoleActionModalOpen({ roleActionModalOpen: false })
      );
    } catch {}
  });

  yield takeEvery(dashboardActions.EditUser, function* (action) {
    try {
      const response: User = yield backend.editUser(
        action.payload.id,
        action.payload.user
      );
      if (action.payload.user.password && response.id) {
        yield put(
          dashboardActions.SetUserPassword({
            userId: response.id,
            password: action.payload.user.password,
          })
        );
      }

      if (action.payload.user.id === storage.getItem(USER_ID_KEY) || '') {
        storage.clear();
        // todo: add this.store.dispatch(authActions.ResetState({}));
        toast.success(
          'You changed your own User information. You must now log back in'
        );
      } else {
        yield put(dashboardActions.GetUsers({}));
        toast.success('Successfully edited User');
      }
      yield put(
        dashboardActions.SetUserActionModalOpen({ userActionModalOpen: false })
      );
    } catch {}
  });

  yield takeEvery(dashboardActions.AddRolesToUser, function* (action) {
    try {
      const response: User = yield backend.addRolesToUser(
        action.payload.userId,
        action.payload.roles
      );
      yield put(dashboardActions.GetUsers({}));
      toast.success("Successfully modified User's Roles");
      yield put(
        dashboardActions.SetUserRoleActionModalOpen({
          userRoleActionModalOpen: false,
        })
      );
    } catch {}
  });

  yield takeEvery(dashboardActions.SetUserPassword, function* (action) {
    try {
      const response: User = yield backend.setUserPassword(
        action.payload.userId,
        action.payload.password
      );
      yield put(dashboardActions.GetUsers({}));
      toast.success('Successfully edited User');
      yield put(
        dashboardActions.SetUserActionModalOpen({
          userActionModalOpen: false,
        })
      );
    } catch {}
  });

  yield takeEvery(dashboardActions.DeleteRole, function* (action) {
    try {
      const response: Role = yield backend.deleteRole(action.payload.id);
      yield put(dashboardActions.GetRoles({}));
      toast.error('Successfully deleted Role');
    } catch {}
  });

  yield takeEvery(dashboardActions.CreateUser, function* (action) {
    try {
      const response: User = yield backend.createUser(action.payload.user);
      yield put(dashboardActions.GetUsers({}));
      toast.success('Successfully created User');
      yield put(
        dashboardActions.SetUserActionModalOpen({
          userActionModalOpen: false,
        })
      );
    } catch {}
  });

  yield takeEvery(dashboardActions.DeleteUser, function* (action) {
    try {
      const response: User = yield backend.deleteRole(action.payload.id);
      yield put(dashboardActions.GetUsers({}));
      toast.error('Successfully deleted User');
    } catch {}
  });

  yield takeEvery(
    dashboardActions.UpdateCategoryIdentifier,
    function* (action) {
      try {
        const response: Identifier = yield backend.updateCategoryIdentifier(
          officeService.getOfficeAPIName(),
          action.payload.categoryId,
          action.payload.identifierId,
          action.payload.identifier
        );
        yield put(dashboardActions.GetCategories({}));
        toast.success('Successfully updated Identifier');
      } catch {}
    }
  );

  yield takeEvery(
    dashboardActions.CreateCategoryIdentifier,
    function* (action) {
      try {
        const response: Identifier = yield backend.createCategoryIdentifier(
          officeService.getOfficeAPIName(),
          action.payload.categoryId,
          action.payload.identifier
        );
        yield put(dashboardActions.GetCategories({}));
        toast.success('Successfully created Identifier');
      } catch (exception) {
        const error = exception as AxiosError;
        if (error.response?.data.message === 'Cannot add the same type twice') {
          toast.error('You cannot add more than one identifier for Evo');
        } else {
          console.error(exception);
          toast.error('There was an error adding your Identifier');
        }
      }
    }
  );

  yield takeEvery(
    dashboardActions.DeleteCategoryIdentifier,
    function* (action) {
      try {
        const response: Identifier = yield backend.deleteCategoryIdentifier(
          officeService.getOfficeAPIName(),
          action.payload.categoryId,
          action.payload.identifierId
        );
        yield put(dashboardActions.GetCategories({}));
        toast.success('Successfully delete Identifier');
      } catch {}
    }
  );
}

export function* dashboardSaga() {
  yield fork(dashboardSideEffects);
}
