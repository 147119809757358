import { ShippingTask, TaskStatus, TaskType } from 'models/Shipping';
import lodash from 'lodash';
import { epochToDateTime } from 'utils/Date';
import { useView } from 'hooks/useView';
import { TaskView } from 'views/TaskView';
import { useEffect, useState } from 'react';
import { Modal } from 'components/Modal';
import { ActionDropdown } from 'components/ActionDropdown';
import { Menu } from '@headlessui/react';
import { classNames } from 'utils/styles';
import { RefreshIcon } from '@heroicons/react/solid';
import { TaskStatusBadge } from 'components/tasks/TaskStatusBadge';
import { Notification } from 'models/Reports';
import { TaskNotification } from 'components/tasks/TaskNotification';
import { Button } from 'components/Button';
import { Size } from 'models/UI';

interface Props {
  task: ShippingTask;
}
export const Task = ({ task }: Props) => {
  const view = useView(TaskView);
  const [resendTask, setResendTask] = useState(false);
  const [taskModalOpen, setTaskModalOpen] = useState(false);

  useEffect(() => {
    if (resendTask) {
      console.log(task.type);
      console.log(TaskType.SHIPPING);
      if (task.type === TaskType.SHIPPING) {
        view.retryShippingTrackingTask();
      }
      setResendTask(false);
      // todo: Trigger resend. Problems -> Update doesn't return the full task, I need
      // todo: Cillian to fix that. Plus we need to know which type of report it is (can use type in response)
    }
  }, [view.state.task]);

  const retryTask = () => {
    setTaskModalOpen(false);
    view.getShippingTask(task.id, task.type);
    setResendTask(true);
  };

  return (
    <tr key={task.id} className="hover:bg-gray-50 border-b">
      <td
        onClick={() => {
          setTaskModalOpen(true);
          view.getShippingTask(task.id, task.type);
        }}
        className="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
      >
        <TaskStatusBadge status={task.status} />
      </td>
      <td
        onClick={() => {
          setTaskModalOpen(true);
          view.getShippingTask(task.id, task.type);
        }}
        className="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
      >
        {lodash.startCase(task.type.toString().toLocaleLowerCase())}
      </td>
      <td
        onClick={() => {
          setTaskModalOpen(true);
          view.getShippingTask(task.id, task.type);
        }}
        className="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
      >
        {epochToDateTime(task.createdAt)}
      </td>
      <td
        onClick={() => {
          setTaskModalOpen(true);
          view.getShippingTask(task.id, task.type);
        }}
        className="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
      >
        {task.id}
      </td>
      {/* <td className="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 my-auto flex">
        {task.status !== TaskStatus.COMPLETE &&
          task.status !== TaskStatus.PENDING && (
            <ActionDropdown
              contents={
                <Menu.Item>
                  {({ active }) => (
                    <a
                      onClick={() => retryTask()}
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group flex items-center px-4 py-2 text-sm cursor-pointer'
                      )}
                    >
                      <RefreshIcon
                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      Resend
                    </a>
                  )}
                </Menu.Item>
              }
            />
          )}
      </td> */}
      {taskModalOpen && (
        <Modal
          title={`Task: ${task.id}`}
          isOpen={taskModalOpen}
          setOpen={setTaskModalOpen}
        >
          {view.state.task && (
            <>
              {' '}
              <div className="mt-5 border-t border-gray-200">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">
                      Status
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <TaskStatusBadge status={task.status} />
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">
                      Created At
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {epochToDateTime(task.createdAt)}
                    </dd>
                  </div>
                  <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">
                      Updated At
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {epochToDateTime(task.updatedAt)}
                    </dd>
                  </div>
                  {/* {task.status !== TaskStatus.COMPLETE &&
                    task.status !== TaskStatus.PENDING && (
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">
                          Retry
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <Button
                            size={Size.Small}
                            colour="blue"
                            text="Retry"
                            icon="RefreshIcon"
                            onClick={() => retryTask()}
                          />
                        </dd>
                      </div>
                    )} */}
                </dl>
              </div>
              {view.state.task.notifications && (
                <>
                  <div className="mt-4">
                    <label
                      htmlFor="comments"
                      className="font-medium text-gray-700"
                    >
                      Notifications
                    </label>
                  </div>
                  <div className="flow-root mt-6">
                    <ul role="list" className="-my-5 divide-y divide-gray-200">
                      {view.state.task.notifications?.map(
                        (notification: Notification, index: number) => {
                          return (
                            <>
                              <TaskNotification notification={notification} />
                            </>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </>
              )}
            </>
          )}
        </Modal>
      )}
    </tr>
  );
};
