import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, fork, takeEvery } from '@redux-saga/core/effects';
import { JWTResponse, User } from 'models/Auth';
import { container } from 'tsyringe';
import { Backend } from 'services/Backend';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { NavigateFunction } from 'react-router';
import {
  ACCESS_TOKEN_KEY,
  NEXT_URL,
  REFRESH_TOKEN_KEY,
  USER_ID_KEY,
} from 'constants/Auth';

export interface AuthState {
  user?: User;
  isLoggingIn: boolean;
  loginRequired: boolean;
}

const initialState = {
  user: {
    username: 'mark',
  },
  isLoggingIn: false,
  loginRequired: false,
} as AuthState;

export const auth = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    ResetState(state: AuthState, action: PayloadAction<{}>) {
      state = initialState;
    },

    SetLoggingIn(
      state: AuthState,
      action: PayloadAction<{ isLoggingIn: boolean }>
    ) {
      state.isLoggingIn = action.payload.isLoggingIn;
    },

    Login(
      state: AuthState,
      action: PayloadAction<{
        username: string;
        password: string;
        navigate: NavigateFunction;
      }>
    ) {},

    LoginError(state: AuthState, action: PayloadAction<{}>) {},

    GetUser(state: AuthState, action: PayloadAction<{ userId: string }>) {},

    SetUser(state: AuthState, action: PayloadAction<{ user: User }>) {
      state.user = action.payload.user;
    },

    SetLoginRequired(
      state: AuthState,
      action: PayloadAction<{ loginRequired: boolean }>
    ) {
      state.loginRequired = action.payload.loginRequired;
    },
  },
});

export const authActions = {
  ...auth.actions,
};

export const authSelector = (state: any) => state[auth.name] as AuthState;

function* authSideEffects() {
  const backend = container.resolve(Backend);
  const storage: Storage = window?.localStorage;

  yield takeEvery(authActions.Login, function* (action) {
    try {
      const response: JWTResponse = yield backend.login(
        action.payload.username,
        action.payload.password
      );
      storage.setItem(ACCESS_TOKEN_KEY, response.token);
      storage.setItem(REFRESH_TOKEN_KEY, response.refreshToken);
      storage.setItem(USER_ID_KEY, response.userId);
      yield put(authActions.SetLoginRequired({ loginRequired: false }));
      yield put(authActions.SetLoggingIn({ isLoggingIn: false }));
      yield put(authActions.GetUser({ userId: response.userId }));
      console.log(storage.getItem(NEXT_URL));
      action.payload.navigate(storage.getItem(NEXT_URL) || '/');
    } catch (exception) {
      const error = (exception as AxiosError).response;
      if (error?.status == 401) {
        yield put(authActions.LoginError({}));
      }
    }
  });

  yield takeEvery(authActions.LoginError, function* () {
    toast.error('Invalid username or password');
    yield put(authActions.SetLoggingIn({ isLoggingIn: false }));
  });

  yield takeEvery(authActions.GetUser, function* (action) {
    try {
      const response: User = yield backend.getUser(action.payload.userId);
      yield put(authActions.SetUser({ user: response }));
    } catch {}
  });
}

export function* authSaga() {
  yield fork(authSideEffects);
}
