import { LoadingIcon } from 'components/LoadingIcon';
import { useView } from 'hooks/useView';
import {
  Availability,
  ProductPrice,
  ProductProvider,
  ProductStock,
  ProductUpdate,
  Provider as ProviderModel,
} from 'models/Product';
import { useEffect, useState } from 'react';
import { ProductView } from 'views/ProductView';
import { PriceGraph } from './PriceGraph';
import { StockGraph } from './StockGraph';
import lodash from 'lodash';

interface Props {
  productProvider: ProductProvider;
}

export const Provider = ({ productProvider }: Props) => {
  const view = useView(ProductView);
  const [isAvailable, setIsAvailable] = useState<Availability>();
  const [providerUpdates, setProviderUpdates] =
    useState<Array<ProductUpdate>>();
  const [masterProviderUpdate, setMasterProviderUpdate] =
    useState<ProductUpdate>();
  const [providerStocks, setProviderStocks] = useState<Array<ProductStock>>();
  const [providerPrices, setProviderPrices] = useState<Array<ProductPrice>>();

  useEffect(() => {
    view.getProviderProductUpdates(productProvider.providerId);
  }, [productProvider]);

  const getProviderStocks = () => {
    return providerUpdates?.map((providerUpdate: ProductUpdate) => {
      return providerUpdate.stock;
    });
  };

  const getProviderPrices = () => {
    return providerUpdates?.map((providerUpdate: ProductUpdate) => {
      return providerUpdate.price;
    });
  };

  useEffect(() => {
    if (view.state.providerProductUpdates && isAvailable == undefined) {
      setIsAvailable(Availability.UNAVAILABLE);
    }
  }, [view.state.providerProductUpdates]);

  useEffect(() => {
    if (view.state.providerProductUpdates) {
      if (productProvider.providerId in view.state.providerProductUpdates) {
        setProviderUpdates(
          view.state.providerProductUpdates[productProvider.providerId]
        );
      }
    }
  }, [view.state.providerProductUpdates]);

  useEffect(() => {
    if (providerUpdates) {
      setMasterProviderUpdate(providerUpdates[0]);
      setProviderStocks(getProviderStocks());
      setProviderPrices(getProviderPrices());
    }
  }, [providerUpdates]);

  useEffect(() => {
    if (masterProviderUpdate) {
      if (masterProviderUpdate.stock) {
        if (masterProviderUpdate.stock.stock > 0) {
          setIsAvailable(Availability.AVAILABLE);
        } else {
          setIsAvailable(Availability.UNAVAILABLE);
        }
      }
    }
  }, [masterProviderUpdate]);

  return (
    <div
      key={productProvider.id}
      className="w-full mb-10 relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
    >
      <div className="flex-1">
        {view.state.providerProductUpdates ? (
          <>
            {isAvailable === Availability.AVAILABLE && (
              <p className="absolute top-0 py-1.5 px-4 bg-green-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                In Stock/Available
              </p>
            )}
            {isAvailable === Availability.UNAVAILABLE && (
              <p className="absolute top-0 py-1.5 px-4 bg-red-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                Out of Stock/Unavailable
              </p>
            )}
            {isAvailable !== Availability.UNAVAILABLE &&
              isAvailable !== Availability.AVAILABLE && (
                <p className="flex absolute top-0 py-1.5 px-4 bg-blue-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                  <LoadingIcon /> <span className="my-auto">Loading</span>
                </p>
              )}
          </>
        ) : null}
        <p className="mt-3 flex items-baseline text-gray-900">
          <span className="text-3xl font-extrabold tracking-tight">
            {lodash.startCase(productProvider.name)}
          </span>
          <span className="ml-1 text-xl font-semibold"></span>
        </p>
        <div className="flex mt-3">
          <div className="w-1/2">
            {providerStocks && <StockGraph productStock={providerStocks} />}
          </div>
          <div className="w-1/2">
            {providerPrices && <PriceGraph productPrices={providerPrices} />}
          </div>
        </div>
      </div>
    </div>
  );
};
