import { useState } from 'react';
import { Notification } from 'models/Reports';
import { TaskStatusBadge } from './TaskStatusBadge';

interface Props {
  notification: Notification;
}

export const TaskNotification = ({ notification }: Props) => {
  const [responseOpen, setResponseOpen] = useState(false);

  return (
    <li key={notification.id} className="py-4">
      <div className="flex items-center space-x-4">
        <div className="flex-1 min-w-0">
          <p className="text-sm font-medium text-gray-900 truncate">
            {notification.id}
          </p>
          {notification.status && (
            <div className="my-1">
              <TaskStatusBadge status={notification.status} />
            </div>
          )}
        </div>
        <div>
          <a
            onClick={() => setResponseOpen(!responseOpen)}
            className="cursor-pointer inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
          >
            {responseOpen ? 'Hide' : 'View'} Response
          </a>
        </div>
      </div>
      {responseOpen && (
        <div className="bg-black mt-2 rounded-sm text-white py-2 px-3">
          {notification.responseData
            ? notification.responseData
            : 'No response'}
        </div>
      )}
    </li>
  );
};
