import * as HeroIcons from '@heroicons/react/solid';
import { Field } from 'formik';
import { useEffect, useState } from 'react';
import { getErrorFormElement } from 'utils/errors';

interface Props {
  id: string;
  type: string;
  name: string;
  label?: string;
  icon?: string;
  placeholder?: string;
  errors: any;
  disabled?: boolean;
}

export const Input = ({
  id,
  name,
  type,
  label,
  icon,
  placeholder,
  errors,
  disabled,
}: Props) => {
  const { ...icons } = HeroIcons;
  // @ts-ignore
  const Icon: JSX.Element = icons[icon];
  const [formErrors, setFormErrors] = useState(errors);

  useEffect(() => {
    setFormErrors(getErrorFormElement(name, errors));
  }, [errors]);

  const _getClasses = () => {
    let baseClasses =
      'shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md';
    if (formErrors) {
      baseClasses +=
        ' border-red-300 text-red-900 border-2 placeholder-red-300 focus:ring-red-500 focus:border-red-500';
    }
    return baseClasses;
  };

  return (
    <div>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div className="mt-1 relative rounded-md shadow-sm">
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {/* @ts-ignore */}
            <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        <Field
          name={name}
          type={type}
          id={id || ''}
          className={_getClasses()}
          placeholder={placeholder || ''}
          disabled={disabled}
        />
      </div>
      {formErrors && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {formErrors[name.substring(name.lastIndexOf('.') + 1)]}
        </p>
      )}
    </div>
  );
};
