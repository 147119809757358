import { createSelector } from '@reduxjs/toolkit';
import { singleton } from 'tsyringe';
import { Notification, Order } from 'models/Reports';
import { ShippingTask, TaskType } from 'models/Shipping';
import { shippingActions, shippingSelector } from 'stores/shipping';
import { View } from 'views/View';

@singleton()
export class TaskView extends View {
  state!: {
    task: ShippingTask;
  };

  constructor() {
    super();
  }

  retryShippingTrackingTask = () => {
    const trackingReport: Array<Order> = [];
    this.state.task.notifications?.map(
      (notification: Notification, index: number) => {
        trackingReport.push(notification.order);
      }
    );
    this.store.dispatch(
      shippingActions.SetTrackingReport({
        trackingReport,
      })
    );
  };

  retryShippingUpdateTask = () => {};

  getShippingTask = (id: string, type: TaskType) => {
    this.store.dispatch(shippingActions.GetShippingTask({ id, type }));
  };

  protected stateMapper = createSelector(shippingSelector, (shipping) => {
    return {
      task: shipping.task,
    };
  });
}
