import { Field, FieldArray, Formik } from 'formik';
import { useView } from 'hooks/useView';
import { Order } from 'models/Reports';
import { Size } from 'models/UI';
import { OrderItems } from 'pages/shipping/OrderItems';
import { EditorView } from 'views/EditorView';
import { Button } from 'components/Button';

export const TrackingForm = () => {
  const view = useView(EditorView);

  return (
    <Formik
      initialValues={view.state.trackingReport}
      validationSchema={view.trackingReportValidationSchema}
      onSubmit={(values) => {
        view.submitTrackingReport(values);
      }}
      enableReinitialize
    >
      {({ errors, handleSubmit, setFieldValue, values }) => (
        <form
          className="space-y-6"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        >
          {console.log(errors)}
          <div className="mt-6">
            <FieldArray
              name={``}
              validateOnChange={true}
              render={(arrayHelpers) => (
                <>
                  {values.map((order: Order, orderIndex: number) => {
                    return (
                      <div className="mb-10">
                        <div className="relative">
                          <div
                            className="absolute inset-0 flex items-center"
                            aria-hidden="true"
                          >
                            <div className="w-full border-t border-gray-300" />
                          </div>
                          <div className="relative flex justify-start">
                            <span className="pr-3 bg-white text-lg font-medium text-gray-900">
                              {order.orderId}
                              <Field
                                name={`${orderIndex}.orderId`}
                                type="text"
                                id={`orderNumber${orderIndex}`}
                                value={order.orderId}
                                className="hidden"
                              />
                            </span>
                          </div>
                        </div>
                        {order.items && (
                          <OrderItems
                            orderIndex={orderIndex}
                            order={order}
                            errors={errors}
                          />
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            />
          </div>
          <div className="-mt-20 flex">
            <div className="mr-2">
              <Button
                colour="blue"
                size={Size.Medium}
                onClick={() => handleSubmit()}
                text="Submit"
                icon="ArrowRightIcon"
              />
            </div>
            <div className="">
              <Button
                colour="gray"
                size={Size.Medium}
                onClick={() => {
                  view.clearTrackingReport();
                }}
                text="Clear Report"
                icon="XIcon"
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
