import { Notification } from 'models/Reports';

export enum TaskStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  COMPLETE = 'COMPLETE',
  RETRYABLE = 'RETRYABLE',
  PARTIALLY_COMPLETE = 'PARTIALLY_COMPLETE',
}

export enum TaskType {
  SHIPPING = 'SHIPPING',
  SHIPPING_UPDATE = 'SHIPPING_UPDATE',
}

export interface ShippingTask {
  id: string;
  status: TaskStatus;
  type: TaskType;
  createdAt: number;
  updatedAt: number;
  notifications?: Array<Notification>;
}
