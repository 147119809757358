import axios from 'axios';
import autobind from 'autobind-decorator';
import { singleton } from 'tsyringe';
import { JWTResponse, Role, User } from 'models/Auth';
import { PaginatedResponse, SearchResponse } from 'models/Dashboard';
import {
  Category,
  Identifier,
  Product,
  ProductPrice,
  Provider,
} from 'models/Product';
import { ShippingTask } from 'models/Shipping';
import { Order, UpdateOrder } from 'models/Reports';

@singleton()
@autobind
export class Backend {
  private readonly apiUrl: string =
    process.env.REACT_APP_API_URL || 'http://localhost:8080/v1';

  async login(username: string, password: string): Promise<JWTResponse> {
    const response = await axios.post(`${this.apiUrl}/authorization`, {
      username,
      password,
    });
    return response.data;
  }

  async refreshToken(refreshToken: string): Promise<JWTResponse> {
    const response = await axios.put(`${this.apiUrl}/authorization`, {
      refreshToken: refreshToken,
    });
    return response.data;
  }

  async getUser(userId: string): Promise<User> {
    const response = await axios.get(`${this.apiUrl}/users/${userId}`);
    return response.data;
  }

  async getRole(roleId: string): Promise<Role> {
    const response = await axios.get(`${this.apiUrl}/roles/${roleId}`);
    return response.data;
  }

  async getProviders(office: string): Promise<Array<Provider>> {
    const response = await axios.get(
      `${this.apiUrl}/products/providers?office=${office.toUpperCase()}`
    );
    return response.data;
  }

  async getProducts(
    office: string,
    page: number = 0
  ): Promise<PaginatedResponse<Product>> {
    const response = await axios.get(
      `${this.apiUrl}/products?office=${office.toUpperCase()}${
        page ? '&page=' + page : ''
      }`
    );
    return response.data;
  }

  async getCategories(
    office: string,
    page: number = 0
  ): Promise<PaginatedResponse<Category>> {
    const response = await axios.get(
      `${this.apiUrl}/products/categories?office=${office.toUpperCase()}${
        page ? '&page=' + page : ''
      }`
    );
    return response.data;
  }

  async searchProducts(
    office: string,
    query: string
  ): Promise<SearchResponse<Product>> {
    const response = await axios.post(
      `${this.apiUrl}/products/search?office=${office.toUpperCase()}`,
      {
        terms: [
          {
            field: 'title',
            value: query,
          },
        ],
        size: '24',
        order: {
          field: 'createdAt',
          direction: 'ASC',
        },
      }
    );
    return response.data;
  }

  async getProduct(office: string, id: string): Promise<Product> {
    const response = await axios.get(
      `${this.apiUrl}/products/${id}?office=${office.toUpperCase()}`
    );
    return response.data;
  }

  async getUsers(page: number = 0): Promise<PaginatedResponse<User>> {
    const response = await axios.get(
      `${this.apiUrl}/users${page ? '?page=' + page : ''}`
    );
    return response.data;
  }

  async getRoles(page: number = 0): Promise<PaginatedResponse<Role>> {
    const response = await axios.get(
      `${this.apiUrl}/roles?size=10${page ? '&page=' + page : ''}`
    );
    return response.data;
  }

  async createRole(role: Role): Promise<Role> {
    const response = await axios.post(`${this.apiUrl}/roles`, role);
    return response.data;
  }

  async addPermissionsToRole(
    roleId: string,
    permissions: Array<string>
  ): Promise<Role> {
    const response = await axios.put(
      `${this.apiUrl}/roles/${roleId}/permissions`,
      {
        permissions,
      }
    );
    return response.data;
  }

  async editRole(id: string, role: Role): Promise<Role> {
    const response = await axios.put(`${this.apiUrl}/roles/${id}`, role);
    return response.data;
  }

  async deleteRole(id: string): Promise<Role> {
    const response = await axios.delete(`${this.apiUrl}/roles/${id}`);
    return response.data;
  }

  async createUser(user: User): Promise<User> {
    const response = await axios.post(`${this.apiUrl}/users`, user);
    return response.data;
  }

  async editUser(id: string, user: User): Promise<User> {
    const response = await axios.put(`${this.apiUrl}/users/${id}`, user);
    return response.data;
  }

  async addRolesToUser(id: string, roles: Array<string>): Promise<User> {
    const response = await axios.put(`${this.apiUrl}/users/${id}/roles`, roles);
    return response.data;
  }

  async setUserPassword(id: string, password: string): Promise<User> {
    const response = await axios.put(`${this.apiUrl}/users/${id}/password`, {
      password,
    });
    return response.data;
  }

  async deleteUser(id: string): Promise<User> {
    const response = await axios.delete(`${this.apiUrl}/users/${id}`);
    return response.data;
  }

  async getShippingTrackingTasks(
    office: string,
    page: number = 0
  ): Promise<PaginatedResponse<ShippingTask>> {
    const response = await axios.get(
      `${this.apiUrl}/tasks/shipping?office=${office}&size=10${
        page ? '&page=' + page : ''
      }`
    );
    return response.data;
  }

  async getShippingUpdateTasks(
    office: string,
    page: number = 0
  ): Promise<PaginatedResponse<ShippingTask>> {
    const response = await axios.get(
      `${this.apiUrl}/tasks/updates?office=${office}&size=10${
        page ? '&page=' + page : ''
      }`
    );
    return response.data;
  }

  async submitShippingUpdateTask(
    office: string,
    shippingUpdate: Array<UpdateOrder>
  ): Promise<ShippingTask> {
    const response = await axios.post(
      `${this.apiUrl}/tasks/updates?office=${office}`,
      { orders: shippingUpdate }
    );
    return response.data;
  }

  async submitShippingTrackingTask(
    office: string,
    shippingUpdate: Array<Order>
  ): Promise<ShippingTask> {
    const response = await axios.post(
      `${this.apiUrl}/tasks/shipping?office=${office}`,
      { orders: shippingUpdate }
    );
    return response.data;
  }

  async getShippingTask(
    office: string,
    type: string,
    id: string
  ): Promise<ShippingTask> {
    const response = await axios.get(
      `${this.apiUrl}/tasks/${type}/${id}?office=${office}`
    );
    return response.data;
  }

  async getProviderProductUpdates(
    office: string,
    providerId: string,
    productId: string
  ): Promise<PaginatedResponse<ProductPrice>> {
    const response = await axios.get(
      `${this.apiUrl}/products/${productId}/providers/${providerId}/updates?office=${office}`
    );
    return response.data;
  }

  async updateCategoryIdentifier(
    office: string,
    categoryId: string,
    identifierId: string,
    identifier: Identifier
  ): Promise<Identifier> {
    const response = await axios.put(
      `${this.apiUrl}/products/categories/${categoryId}/identifiers/${identifierId}?office=${office}`,
      {
        name: identifier.name,
        value: identifier.value,
        type: identifier.type,
      }
    );
    return response.data;
  }

  async createCategoryIdentifier(
    office: string,
    categoryId: string,
    identifier: Identifier
  ): Promise<Identifier> {
    const response = await axios.post(
      `${this.apiUrl}/products/categories/${categoryId}/identifiers?office=${office}`,
      {
        name: identifier.name,
        value: identifier.value,
        type: 'EVO', // todo: replace when we have more than evo
      }
    );
    return response.data;
  }

  async deleteCategoryIdentifier(
    office: string,
    categoryId: string,
    identifierId: string
  ): Promise<Identifier> {
    const response = await axios.delete(
      `${this.apiUrl}/products/categories/${categoryId}/identifiers/${identifierId}?office=${office}`
    );
    return response.data;
  }
}
